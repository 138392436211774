import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Button, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

class SetForm extends React.Component {

    constructor(props) {
        super(props);

        this.handleUserInput = this.handleUserInput.bind(this);
    }

    handleUserInput(e) {
        const name = e.target.name;
        let value = parseInt(e.target.value);

        this.props.editSet(this.props.id, name, value);
    }

    render() {
        return (
            <Row className="mt-2">
                <Col xs={3}>
                    <Form.Group controlId="setTimes">
                        <Form.Control type="text"
                                      name="times"
                                      value={this.props.times}
                                      maxLength="5"
                                      placeholder="Kerrat"
                                      onChange={this.handleUserInput}/>

                        {this.props.errors.times && (
                            <Form.Text className="text-muted">
                                {this.props.errors.times}
                            </Form.Text>
                        )}
                    </Form.Group>
                </Col>

                <Col xs={1} className="text-center">
                    <div className="pt-2">
                        x
                    </div>
                </Col>

                <Col xs={3}>
                    <Form.Group controlId="setTimes">
                        <Form.Control type="text"
                                      name="min"
                                      value={this.props.min}
                                      maxLength="5"
                                      placeholder="Min"
                                      onChange={this.handleUserInput}/>

                        {this.props.errors.min && (
                            <Form.Text className="text-muted">
                                {this.props.errors.min}
                            </Form.Text>
                        )}
                    </Form.Group>
                </Col>

                <Col xs={1} className="text-center">
                    <div className="pt-2">
                        -
                    </div>
                </Col>

                <Col xs={3}>
                    <Form.Group controlId="setTimes">
                        <Form.Control type="text"
                                      name="max"
                                      value={this.props.max}
                                      maxLength="5"
                                      placeholder="Max"
                                      onChange={this.handleUserInput}/>

                        {this.props.errors.max && (
                            <Form.Text className="text-muted">
                                {this.props.errors.max}
                            </Form.Text>
                        )}
                    </Form.Group>
                </Col>

                <Col xs={1} className="text-right">
                    <Button variant="danger"
                            className="icon-btn"
                            onClick={() => this.props.deleteSet(this.props.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </Col>
            </Row>
        )
    }
}

export default SetForm;