import {axiosInstance as axios} from '../axiosConfig';
import AxiosHelper from './axiosHelper';


class MoveHelper {

    static getMoves() {
        return new Promise(function (resolve, reject) {
            try {
                axios.get("moves")
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }
}

export default MoveHelper;