import {axiosInstance as axios} from '../../axiosConfig';
import AxiosHelper from './../axiosHelper';


class WorkoutMoveHelper {

    static createMove(workoutId, moveId, comments) {

        return new Promise(function (resolve, reject) {
            try {

                axios.post("workouts/" + workoutId + "/moves", {
                    move: moveId,
                    comments
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    static updateMove(workoutId, workoutMoveId, moveId, comments) {

        return new Promise(function (resolve, reject) {
            try {

                axios.put("workouts/" + workoutId + "/moves/"+ workoutMoveId, {
                    move: moveId,
                    comments
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    static async updateMoveOrder(workoutId, workoutMoveId, newOrder) {
        try {
            await axios.put("workouts/" + workoutId + "/moves/"+ workoutMoveId + "/order", {
                order: newOrder
            });
            return "";

        } catch (e) {
            const err = AxiosHelper.handleRequestError(e);
            throw ({code: err.code});
        }
    }

    static deleteMove(workoutId, workoutMoveId) {

        return new Promise(function (resolve, reject) {
            try {

                axios.delete("workouts/" + workoutId + "/moves/"+ workoutMoveId, {

                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    static linkMove(workoutId, workoutMoveId) {

        return new Promise(function (resolve, reject) {
            try {

                axios.put("workouts/" + workoutId + "/moves/"+ workoutMoveId + "/link", {
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    static unlinkMove(workoutId, workoutMoveId) {

        return new Promise(function (resolve, reject) {
            try {

                axios.put("workouts/" + workoutId + "/moves/"+ workoutMoveId + "/unlink", {
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }
}

export default WorkoutMoveHelper;