import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Alert, Button} from "react-bootstrap";
import WorkoutProgramDayForm from "./WorkoutProgramDayForm";
import ProgramDayHelper from "../../../helpers/programs/ProgramDayHelper";
import ContentHeader from "../../ContentHeader";

class NewProgramDay extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            programId: '',
            error: '',
            showForm: false
        };

        this.createDay = this.createDay.bind(this);
        this.showError = this.showError.bind(this);
    }

    componentDidMount() {
        if (this.props.location.state) {
            if (!Number.isInteger(this.props.location.state.programId)) {
                this.setState({
                    error: 'Harjoitusohjelmaa ei ole valittuna.',
                    showForm: false
                });
            } else {
                this.setState({
                    programId: this.props.location.state.programId,
                    showForm: true
                });
            }
        } else {
            this.setState({
                error: 'Harjoitusohjelmaa ei ole valittuna.',
                showForm: false
            });
        }
    }

    createDay(name) {
        ProgramDayHelper.createDay(
            this.state.programId,
            name
        ).then(function (result) {
            // Redirect to programs
            this.props.history.push('/programs')

        }.bind(this))
            .catch(error => {
                this.setState({error: 'Harjoituspäivän luonti epäonnistui.'})
            })
    }

    showError(text) {
        this.setState({
            error: text
        })
    }

    render() {

        return (
            <Row className="mb-5">
                <ContentHeader title="Uusi harjoituspäivä" />

                {this.state.error && (
                    <Col xs={12} className="mb-3">
                        <Alert variant="danger">
                            {this.state.error}
                        </Alert>
                    </Col>
                )}

                {this.state.showForm ? (
                    <Col xs={12}>
                        <WorkoutProgramDayForm
                            saveBtnText="Tallenna"
                            showError={this.showError}
                            dayId=""
                            programId=""
                            loadData={false}
                            saveDay={this.createDay}
                            historyBack={this.props.history.goBack}
                        />
                    </Col>
                ): (
                    <Button variant="default"
                            onClick={this.props.history.goBack}>
                        Peruuta
                    </Button>
                )}
            </Row>
        )
    }
}

export default NewProgramDay;