import {axiosInstance as axios} from '../axiosConfig';
import AxiosHelper, {RequestException}  from './axiosHelper';

export const WorkoutTypes = {
    gym: "gym"
}

class WorkoutHelper {
    /**
     * Is the same weight in the same set group
     * @param a
     * @param b
     * @returns {boolean}
     */
    static compareSets(a, b) {
        return a.set_group === b.set_group && a.weight === b.weight;
    };

    static createWorkout(type, programDayId) {
        return new Promise(function (resolve, reject) {
            try {
                axios.post("workouts", {
                    type,
                    programDay: programDayId
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    static getWorkout(id) {
        return new Promise(function (resolve, reject) {
            try {
                axios.get("workouts/" + id, {
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }


    static getWorkouts(type) {
        return new Promise(function (resolve, reject) {
            try {
                axios.get("workouts", {
                    params: {
                        type
                    }
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    static getThisWeekWorkouts() {
        return new Promise(function (resolve, reject) {
            try {
                axios.get("workouts/week")
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    static deleteWorkout(id) {
        return new Promise(function (resolve, reject) {
            try {
                axios.delete("workouts/" + id, {
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    static async updateRPE(workoutId, rpe) {
        try {
            const updated = await axios.post('workouts/' + workoutId + '/rpe', {
                rpe
            });
            return updated.data;

        } catch (e) {
            throw new RequestException(e);
        }
    }
}

export default WorkoutHelper;