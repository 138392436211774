const RPEValues = {
    0: "Lepo",
    1: "Hyvin kevyt",
    2: "Kevyt",
    3: "Kohtalaisen rasittava",
    4: "",
    5: "Rasittava",
    6: "",
    7: "Hyvin rasittava",
    8: "",
    9: "",
    10: "Maksimaalinen"
}

class RPEHelper {

    static getRPEText(value, checkLowerValues = false) {
        if (!value || value < 0 || value > 10) {
            return '';
        }

        const valueText = RPEValues[value];

        if (!checkLowerValues) {
            return valueText;
        }

        if (valueText.length > 0) {
            return valueText;
        } else {
            for (let i = value; i >= 0; i--) {
                if (RPEValues[i].length !== 0) {
                    return RPEValues[i];
                }
            }
        }

        return '';
    }
}

export default RPEHelper;