import {axiosInstance as axios} from '../../axiosConfig';
import AxiosHelper from './../axiosHelper';


class WorkoutSetHelper {

    static createSet(workoutId, moveId, sets) {

        return new Promise(function (resolve, reject) {
            try {

                const setData = WorkoutSetHelper.removeNewIds(sets);

                axios.post("workouts/" + workoutId + "/moves/" + moveId + "/sets", {
                    sets: setData
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    static updateSet(workoutId, moveId, group, sets) {

        return new Promise(function (resolve, reject) {
            try {

                const setData = WorkoutSetHelper.removeNewIds(sets);

                axios.put("workouts/" + workoutId + "/moves/" + moveId + "/sets/" + group, {
                    sets: setData
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    static deleteSet(workoutId, moveId, group) {

        return new Promise(function (resolve, reject) {
            try {

                axios.delete("workouts/" + workoutId + "/moves/" + moveId + "/sets/" + group, {
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    static removeNewIds(sets) {
        return sets.map((item) => {
            if (typeof item.id === "string" && item.id.startsWith("new-")) {
                delete item.id;
            }

            return item;
        });

    }
}

export default WorkoutSetHelper;