import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Dashboard from './dashboard/Dashboard';
import Login from "./login/Login";
import WorkoutPrograms from "./programs/WorkoutPrograms";
import PrivateRoute from "./PrivateRoute";
import { isUserLogged } from "../redux/reducers/user";
import {connect} from "react-redux";
import NewProgram from "./programs/NewProgram";
import EditWorkoutProgram from "./programs/EditWorkoutProgram";
import NewProgramDay from "./programs/days/NewProgramDay";
import EditProgramDay from "./programs/days/EditProgramDay";
import ProgramDayStats from "./programs/days/ProgramDayStats";
import NewProgramMove from "./programs/moves/NewProgramMove";
import EditProgramMove from "./programs/moves/EditProgramMove";
import GymWorkouts from "./workouts/GymWorkouts";
import GymWorkout from "./workouts/GymWorkout";
import BodyStats from "./stats/BodyStats";

class Content extends React.Component {

    render() {
        return (
            <Container>
                <div>

                    <Switch>

                        {/*<PrivateRoute exact path="/dashboard" component={Dashboard}/>*/}

                        <Route exact path="/">
                            {this.props.logged ? <Redirect to="/dashboard" /> : <Login />}
                        </Route>

                        <Route exact path="/inbody" component={BodyStats} />

                        <PrivateRoute exact path="/dashboard" component={Dashboard}/>

                        <PrivateRoute exact path="/workouts" component={GymWorkouts}/>
                        <PrivateRoute exact path="/workouts/:id" component={GymWorkout}/>

                        <PrivateRoute exact path="/programs" component={WorkoutPrograms}/>
                        <PrivateRoute exact path="/programs/new" component={NewProgram}/>
                        <PrivateRoute exact path="/programs/:id/edit" component={EditWorkoutProgram}/>
                        <PrivateRoute exact path="/programs/day/new" component={NewProgramDay}/>
                        <PrivateRoute exact path="/programs/:programId/day/:dayId/edit" component={EditProgramDay}/>
                        <PrivateRoute exact path="/programs/move/new" component={NewProgramMove}/>
                        <PrivateRoute exact path="/programs/:programId/days/:dayId/move/:moveId/edit" component={EditProgramMove}/>
                        <PrivateRoute exact path="/programs/:programId/stats" component={ProgramDayStats}/>

                        {/*<Route exact path="/" component={Login}/>
                        <PrivateRoute exact path="/dashboard" component={Dashboard}/>*/}

                        {/*
                        {isUserLogged ? (
                            <PrivateRoute exact path="/dashboard" component={Dashboard}/>
                        ) : (
                            <Route exact path="/">
                                {isUserLogged ? <Redirect to="/dashboard" /> : <Login />}
                            </Route>
                        )}*/}

                    </Switch>

                </div>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        logged: isUserLogged(state)
    }
}

export default connect(mapStateToProps)(Content)