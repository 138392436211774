import { LOGIN, LOG_OUT } from "../actionTypes";

export const loginUser = (email) => ({
    type: LOGIN,
    email
});

export const logoutUser = () => ({
    type: LOG_OUT
});

