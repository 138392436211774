import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Alert, Button} from "react-bootstrap";
import ContentHeader from "../ContentHeader";
import MoveSelector from "./MoveSelector";
import WorkoutMoveHelper from "../../helpers/workouts/WorkoutMoveHelper";
import ResizingTextarea from "../Forms/ResizingTextarea";

class WorkoutMoveForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            comments: this.props.initialComments,
            error: '',
            moveId: this.props.initialMoveId,
            moveError: ''
        };

        this.handleUserInput = this.handleUserInput.bind(this);
        this.saveMove = this.saveMove.bind(this);
        this.selectMove = this.selectMove.bind(this);
    }

    handleUserInput(e) {
        const name = e.target.name;
        let value = e.target.value;

        this.setState({
            [name]: value
        })
    }

    saveMove() {
        if (this.validateMove(this.state.moveId)) {
            const comments = this.state.comments !== null ? this.state.comments.trim() : '';

            if (this.props.isNewMove) {
                // Create move
                WorkoutMoveHelper.createMove(
                    this.props.workoutId,
                    this.state.moveId,
                    comments
                ).then(function (move) {
                    this.props.showEditedMove(move);
                }.bind(this))
                    .catch(error => {
                        this.setState({error: 'Tallennus epäonnistui.'});
                    })
            } else {
                // Update move
                WorkoutMoveHelper.updateMove(
                    this.props.workoutId,
                    this.props.workoutMoveId,
                    this.state.moveId,
                    comments
                ).then(function (move) {
                    this.props.showEditedMove(move);
                }.bind(this))
                    .catch(error => {
                        this.setState({error: 'Tallennus epäonnistui.'});
                    })
            }
        }
    }

    selectMove(id) {
        if (this.validateMove(id)) {
            this.setState({
                moveId: id
            })
        }
    }

    validateMove(id) {
        const moveId = parseInt(id);

        if (!Number.isInteger(moveId)) {
            this.setState({moveError: 'Valitse liike'});
            return false;
        } else {
            this.setState({moveError: ''});
            return true;
        }
    }

    render() {
        let pageTitle = "Uusi liike";

        if (!this.props.isNewMove) {
            pageTitle = "Muokkaa liikettä";
        }

        return (
            <Row className="mb-5">
                <ContentHeader title={pageTitle} />

                <Col xs={12}>
                    <MoveSelector
                        error={this.state.moveError}
                        selectedMove={this.state.moveId}
                        selectMove={this.selectMove}
                    />
                </Col>

                <Col xs={12}>
                    <ResizingTextarea
                        title="Kommentit"
                        name="comments"
                        value={this.state.comments}
                        handleChange={this.handleUserInput}
                    />
                </Col>


                {this.state.error && (
                    <Col xs={12} className="mt-3">
                        <Alert variant="danger">
                            {this.state.error}
                        </Alert>
                    </Col>
                )}

                <Col xs={12}>
                    <Row className="mt-4">

                        <Col xs={6} md={3}>
                            <Button variant="default"
                                    className="btn-block"
                                    onClick={this.props.closeForm}>
                                Peruuta
                            </Button>
                        </Col>
                        <Col xs={6} md={3}>
                            <Button variant="info"
                                    className="btn-block"
                                    onClick={this.saveMove}>
                                Tallenna
                            </Button>
                        </Col>
                    </Row>
                </Col>

            </Row>
        )
    }
}

export default WorkoutMoveForm;