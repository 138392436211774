import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DateTimeHelper from "../../helpers/DateTimeHelper";

class StatDetails extends React.Component {

    render() {

        return (
            <Row className="mb-3 mb-md-3">
                <Col xs={12} md={2}>
                    <span className="font-weight-bold">
                        {DateTimeHelper.formatDate(this.props.date)}
                    </span>
                </Col>
                <Col xs={12} md={7}>
                    {this.props.comments}
                </Col>
                <Col xs={12} md={3}>
                    <small className="text-muted">
                        {this.props.device}
                    </small>
                </Col>
            </Row>
        )
    }
}

export default StatDetails;