import React from 'react';
import RPEHelper from "../../../helpers/workouts/RPEHelper";
import '../../../assets/css/rpe.css';

function RPEBadge(props) {
    return (
        <div className={`pl-1 pr-1 mb-1 rpe-${props.value} rpe-badge small`}>
            {props.value} {RPEHelper.getRPEText(props.value, true)}
        </div>
    );
}

export default RPEBadge;