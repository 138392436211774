import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {Alert} from "react-bootstrap";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import BodyStatsHelper from "../../helpers/BodyStats/BodyStatsHelper";
import _ from "lodash";
import StatDetails from "./StatDetails";
import DateTimeHelper from "../../helpers/DateTimeHelper";
import QueryString from "querystring";
import ContentHeader from "../ContentHeader";

class BodyStats extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: '',
            loaded: false,
            stats: []
        }
    }

    componentDidMount() {
        // Check token & get data
        const parsedQuery = QueryString.parse(this.props.location.search);
        const token = parsedQuery['?token'];

        BodyStatsHelper.getBodyStats(token).then(function (stats) {
            this.setState({
                loaded: true,
                stats
            })

        }.bind(this))
            .catch(error => {
                this.setState({
                    loaded: true,
                    error: 'Tietojen haku epäonnistui.'
                })
            })



    }

    getChart(title, subtitle, yAxisTitle, seriesData, dates) {
        let series = [];
        let showInLegend = true;

        if (seriesData.length <= 1) {
            showInLegend = false;
        }

        for (let s of seriesData) {
            series.push({
                grouping: false,
                enableMouseTracking: false,
                showInLegend: showInLegend,
                stacking: false,
                borderColor: '#d5d3d3',
                format: '',
                dataLabels: {
                    enabled: true
                },
                name: s.name,
                data: s.data
            });
        }

        return {
            credits: {
                enabled: false
            },
            chart: {
                type: 'line'
            },
            title: {
                text: title
            },
            subtitle: {
                text: subtitle
            },
            xAxis: {
                visible: true,
                categories: dates
            },
            yAxis: {
                visible: true,
                title: {
                    text: yAxisTitle
                }
            },
            tooltip: {
                enabled: false
            },
            series: series
        }
    }

    getData(columnName) {
        let data = _.map(this.state.stats, columnName);
        return _.map(data, function (item) {
            return Number.parseFloat(item);
        });

    }

    render() {

        // Dates
        let dates = _.map(this.state.stats, 'date');
        dates = _.map(dates, function (item) {
            return DateTimeHelper.formatDate(item);
        });

        // Weight
        let weights = this.getData("weight");
        const w = [{
            name: '',
            data: weights
        }];

        const weightOptions = this.getChart(
            "Paino",
            "",
            "Paino (kg)",
            w,
            dates
        );

        // Muscle mass
        let muscleMass = this.getData("muscle_mass");
        const mm = [{
            name: '',
            data: muscleMass
        }];

        const muscleOptions = this.getChart(
            "Lihasmassa",
            "",
            "Lihasmassa (kg)",
            mm,
            dates
        );

        // Body fat percent
        let fatPercent = this.getData("body_fat_percent");
        const fp = [{
            name: '',
            data: fatPercent
        }];

        const fatOptions = this.getChart(
            "Rasvaprosentti",
            "",
            "Rasva (%)",
            fp,
            dates
        );

        // Visceral fat
        let visceralFat = this.getData("visceral_fat");
        const vf = [{
            name: '',
            data: visceralFat
        }];

        const visceralFatOptions = this.getChart(
            "Viskeraalirasva",
            "",
            "Viskeraalirasva",
            vf,
            dates
        );

        // Legs

        // Percent
        let rightLeg = this.getData("muscle_mass_percent_right_leg");
        let leftLeg = this.getData("muscle_mass_percent_left_leg");

        const legs = [
            {
                name: "Oikea jalka",
                data: rightLeg
            },
            {
                name: "Vasen jalka",
                data: leftLeg
            }
        ];

        const legOptions = this.getChart(
            "Jalat (%)",
            "Rasvaton massa suhteessa nyt mitattuun painoon (prosenttia normaaliarvosta)",
            "%",
            legs,
            dates
        );

        // Kg
        let rightLegKg = this.getData("muscle_mass_kg_right_leg");
        let leftLegKg = this.getData("muscle_mass_kg_left_leg");

        const legsKg = [
            {
                name: "Oikea jalka",
                data: rightLegKg
            },
            {
                name: "Vasen jalka",
                data: leftLegKg
            }
        ];

        const legOptionsKg = this.getChart(
            "Jalat (kg)",
            "Rasvaton massa suhteessa ihannepainoon",
            "Kg",
            legsKg,
            dates
        );

        // Arms

        // Percent
        let rightArm = this.getData("muscle_mass_percent_right_arm");
        let leftArm = this.getData("muscle_mass_percent_left_arm");

        const arms = [
            {
                name: "Oikea käsi",
                data: rightArm
            },
            {
                name: "Vasen käsi",
                data: leftArm
            }
        ];


        const armOptions = this.getChart(
            "Kädet (%)",
            "Rasvaton massa suhteessa nyt mitattuun painoon (prosenttia normaaliarvosta)",
            "%",
            arms,
            dates
        );

        // Kg
        let rightArmKg = this.getData("muscle_mass_kg_right_arm");
        let leftArmKg = this.getData("muscle_mass_kg_left_arm");

        const armsKg = [
            {
                name: "Oikea käsi",
                data: rightArmKg
            },
            {
                name: "Vasen käsi",
                data: leftArmKg
            }
        ];


        const armOptionsKg = this.getChart(
            "Kädet (kg)",
            "Rasvaton massa suhteessa ihannepainoon",
            "Kg",
            armsKg,
            dates
        );

        // Middle body

        // Percent
        let middleBody = this.getData("muscle_mass_percent_middle_body");
        const mb = [{
            name: '',
            data: middleBody
        }];

        const middleBodyOptions = this.getChart(
            "Keskivartalo (%)",
            "Rasvaton massa suhteessa nyt mitattuun painoon (prosenttia normaaliarvosta)",
            "%",
            mb,
            dates
        );

        // Kg
        let middleBodyKg = this.getData("muscle_mass_kg_middle_body");
        const mbkg = [{
            name: '',
            data: middleBodyKg
        }];

        const middleBodyOptionsKg = this.getChart(
            "Keskivartalo (kg)",
            "Rasvaton massa suhteessa ihannepainoon",
            "Kg",
            mbkg,
            dates
        );

        // Descriptions
        const details = this.state.stats.map((item) => {

            return (
               <StatDetails
                    key={item.id}
                    date={item.date}
                    comments={item.comments}
                    device={item.device}
               />
            )
        });



        return (
            <Row className="mb-5">
                <ContentHeader title="InBody" />

                {this.state.error && this.state.loaded && (
                    <Col xs={12}>
                        <Alert variant="danger">
                            {this.state.error}
                        </Alert>
                    </Col>
                )}

                {!this.state.error && this.state.loaded && (

                    <Col xs={12}>

                        {details}

                        <Row className="mt-5">
                            <Col xs={12}>
                                <HighchartsReact
                                    key='weight'
                                    highcharts={Highcharts}
                                    options={weightOptions}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-5">
                            <Col xs={12}>
                                <HighchartsReact
                                    key='weight'
                                    highcharts={Highcharts}
                                    options={fatOptions}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-5">
                            <Col xs={12}>
                                <HighchartsReact
                                    key='visceralFat'
                                    highcharts={Highcharts}
                                    options={visceralFatOptions}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-5">
                            <Col xs={12}>
                                <HighchartsReact
                                    key='weight'
                                    highcharts={Highcharts}
                                    options={muscleOptions}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-5">
                            <Col xs={12}>
                                <HighchartsReact
                                    key='legs'
                                    highcharts={Highcharts}
                                    options={legOptions}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-5">
                            <Col xs={12}>
                                <HighchartsReact
                                    key='legsKg'
                                    highcharts={Highcharts}
                                    options={legOptionsKg}
                                />
                            </Col>
                        </Row>


                        <Row className="mt-5">
                            <Col xs={12}>
                                <HighchartsReact
                                    key='arms'
                                    highcharts={Highcharts}
                                    options={armOptions}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-5">
                            <Col xs={12}>
                                <HighchartsReact
                                    key='armsKg'
                                    highcharts={Highcharts}
                                    options={armOptionsKg}
                                />
                            </Col>
                        </Row>


                        <Row className="mt-5">
                            <Col xs={12}>
                                <HighchartsReact
                                    key='middleBody'
                                    highcharts={Highcharts}
                                    options={middleBodyOptions}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-5">
                            <Col xs={12}>
                                <HighchartsReact
                                    key='middleBodyKg'
                                    highcharts={Highcharts}
                                    options={middleBodyOptionsKg}
                                />
                            </Col>
                        </Row>
                    </Col>
                )}

            </Row>
        )
    }
}

export default BodyStats;