import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import WorkoutProgramDay from "./days/WorkoutProgramDay";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPencilAlt, faPlus, faChartLine} from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import RouteHelper from "../../helpers/RouteHelper";

class WorkoutProgram extends React.Component {

    render() {

        let days = "";

        if (this.props.program.days) {
            days = this.props.program.days.map((day) => {
                return (
                    <WorkoutProgramDay
                        id={day.id}
                        name={day.name}
                        moves={day.moves}
                        dayId={day.id}
                        programId={this.props.program.id}
                        key={day.id} />
                )
            })
        }

        return (
            <Row className="mt-3 mb-5">
                <Col xs={12}>
                    <Row>
                        <Col xs={12} className="mt-5 section-header section-header-top">
                            <Row>
                                <Col xs={12} md={10}>
                                    <h5>
                                        {this.props.program.name}
                                    </h5>
                                </Col>
                                <Col xs={12} md={2} className="text-right">
                                    <Link to={{
                                              pathname: "/programs/day/new",
                                              state: { programId: this.props.program.id }
                                          }}
                                          className="mr-2">
                                        <Button variant="light"
                                                className="icon-btn">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                    </Link>
                                    <Link to={`/programs/${this.props.program.id}/edit`} className="mr-2">
                                        <Button variant="light"
                                                className="icon-btn">
                                            <FontAwesomeIcon icon={faPencilAlt} />
                                        </Button>
                                    </Link>
                                    <Link to={RouteHelper.getProgramStatsUrl(this.props.program.id)}>
                                        <Button variant="light"
                                                className="icon-btn">
                                            <FontAwesomeIcon icon={faChartLine} />
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={12} className="section-content">
                            {this.props.program.description && (
                                <Row>
                                    <Col xs={12} className="pt-3 text-center">
                                        {this.props.program.description.split("\n").map((i,key) => {
                                            return <div key={key}>{i}</div>;
                                        })}
                                    </Col>
                                </Row>
                            )}

                            <Row>
                                <Col xs={12} className="pt-5 pb-5">
                                    {days}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default WorkoutProgram;