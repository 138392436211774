import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Alert} from "react-bootstrap";
import ProgramMoveForm from "./ProgramMoveForm";
import ProgramMoveHelper from "../../../helpers/programs/ProgramMoveHelper";
import ContentHeader from "../../ContentHeader";

class EditProgramMove extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dayId: '',
            moveId: '',
            programId: '',
            error: '',
            showForm: false
        };

        this.saveMove = this.saveMove.bind(this);
        this.showError = this.showError.bind(this);
    }

    componentDidMount() {
        const dayId = parseInt(this.props.match.params.dayId);
        const moveId = parseInt(this.props.match.params.moveId);
        const programId = parseInt(this.props.match.params.programId);

        if (!Number.isInteger(dayId) || !Number.isInteger(moveId) || !Number.isInteger(programId)) {
            this.showError("Harjoitusliikettä ei löytynyt.", false);
        } else {
            this.setState({
                dayId,
                moveId,
                programId
            })
        }
    }

    saveMove(move, rest, comments, sets) {

        ProgramMoveHelper.updateMove(
            this.state.programId,
            this.state.dayId,
            this.state.moveId,
            move,
            rest,
            comments,
            sets
        ).then(function (result) {
            // Redirect to programs
            this.props.history.push('/programs')

        }.bind(this))
            .catch(error => {
                this.showError("Päivitys epäonnistui", true);
            })
    }

    showError(text, showForm) {
        this.setState({
            error: text,
            showForm
        })
    }

    render() {

        return (
            <Row className="mb-5">
                <ContentHeader title="Muokkaa harjoitusliikettä" />

                {this.state.error && (
                    <Col xs={12} className="mb-3">
                        <Alert variant="danger">
                            {this.state.error}
                        </Alert>
                    </Col>
                )}

                <Col xs={12}>
                    <ProgramMoveForm
                        programId={this.state.programId}
                        dayId={this.state.dayId}
                        moveId={this.state.moveId}
                        showError={this.showError}
                        loadData={true}
                        saveMove={this.saveMove}
                        historyBack={this.props.history.goBack}
                    />
                </Col>

            </Row>
        )
    }
}

export default EditProgramMove;