import React from 'react';
import {Form} from "react-bootstrap";
import MoveHelper from "../../helpers/MoveHelper";

class MoveSelector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            moves: []
        };
    }

    componentDidMount() {
        // Get moves
        MoveHelper.getMoves().then(function (moves) {
            // Select first move if there is no move selected
            if (!this.props.selectedMove && moves[0]) {
                this.props.selectMove(moves[0].id);
            }

            this.setState({moves})

        }.bind(this))
            .catch(error => {
            })
    }

    render() {
        const moves = this.state.moves.map((move) => {
            return (
                <option value={move.id} key={move.id}>
                    {move.name}
                </option>
            )
        });

        return (
            <Form.Group controlId="ProgramMove">
                <Form.Label>
                    Liike *
                </Form.Label>
                <Form.Control as="select"
                              name="move"
                              value={this.props.selectedMove}
                              onChange={(e) => this.props.selectMove(e.target.value)}>
                    { moves }
                </Form.Control>

                {this.props.error && (
                    <Form.Text className="text-muted">
                        {this.props.error}
                    </Form.Text>
                )}
            </Form.Group>
        )
    }
}

export default MoveSelector;