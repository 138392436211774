import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import _ from "lodash";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import DateTimeHelper from "../../../helpers/DateTimeHelper";
import ProgramHelper from "../../../helpers/programs/ProgramHelper";
import WorkoutHelper from "../../../helpers/workoutHelper";

const ProgramDayStats = () => {
    const {programId} = useParams();
    const [days, setDays] = useState([]);

    async function getStats() {
        try {
            const response = await ProgramHelper.getProgramMovesAndWorkouts(programId);
            setDays(response);
        } catch (e) {
            //
        }
    }

    useEffect(() => {
        getStats();
    }, []);

    const calcRepsForWorkouts = (workouts) => {
        const workoutSets = _.map(workouts, 'sets');
        let result = [];
        for (let s in workoutSets) {
            result.push(_.sumBy(workoutSets[s], 'reps'));
        }
        return result;
    };

    const calcWeightsForWorkouts = (workouts) => {
        const workoutSets = _.map(workouts, 'sets');

        let result = [];
        for (let s in workoutSets) {
            // Remove duplicate weights in the same set (needed for clusters)
            const sets = _.uniqWith(workoutSets[s], WorkoutHelper.compareSets);
            const nroOfSets = sets.length;

            if (nroOfSets > 0) {
                const weightSum =_.sumBy(sets, function(item) {
                    return parseFloat(item.weight);
                });

                result.push(parseFloat((weightSum / nroOfSets).toFixed(2)));
            } else {
                result.push(0);
            }
        }
        return result;
    };

    const getDates = (workouts) => {
        let dates = _.map(workouts, 'date');
        return _.map(dates, function (item) {
            return DateTimeHelper.formatDate(item);
        });
    };

    const getRepsChart = (moveName, workouts) => {
        workouts = _.sortBy(workouts, ['date']);

        return getChart(
            moveName,
            "",
            "",
            [{
                name: 'Toistot yhteensä',
                data: calcRepsForWorkouts(workouts)
            }],
            getDates(workouts),
            '#8803fc'
        );
    };

    const getWeightsChart = (moveName, workouts) => {
        workouts = _.sortBy(workouts, ['date']);

        return getChart(
            "",
            "",
            "",
            [{
                name: 'Painojen keskiarvo treenissä (kg)',
                data: calcWeightsForWorkouts(workouts)
            }],
            getDates(workouts),
            '#fcba03'
        );
    };

    const getChart = (title, subtitle, yAxisTitle, seriesData, dates, color) => {
        let series = [];
        let showInLegend = true;

        /*if (seriesData.length <= 1) {
            showInLegend = false;
        }*/

        for (let s of seriesData) {
            series.push({
                color: color,
                grouping: false,
                enableMouseTracking: false,
                showInLegend: showInLegend,
                stacking: false,
                borderColor: '#d5d3d3',
                format: '',
                dataLabels: {
                    enabled: true
                },
                name: s.name,
                data: s.data
            });
        }

        return {
            credits: {
                enabled: false
            },
            chart: {
                type: 'line'
            },
            title: {
                text: title
            },
            subtitle: {
                text: subtitle
            },
            xAxis: {
                visible: true,
                categories: dates
            },
            yAxis: {
                visible: true,
                title: {
                    text: yAxisTitle
                }
            },
            tooltip: {
                enabled: false
            },
            series: series
        }
    };

    return (
        <>
            {days.map(function (item) {
                return (
                    <div key={item.day.id}>
                        <h5 className="mt-5 mb-5 text-center">
                            {item.day.name ?? ''}
                        </h5>

                        {item.moves.map(function (move) {
                            return (
                                <div className="mb-4" key={move.id}>
                                    <div className="mb-2">
                                        <HighchartsReact
                                            key='move'
                                            highcharts={Highcharts}
                                            options={getRepsChart(move.name, move.workouts)}
                                        />
                                    </div>
                                    <div>
                                        <HighchartsReact
                                            key='weight'
                                            highcharts={Highcharts}
                                            options={getWeightsChart(move.name, move.workouts)}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </>
    );
};

export default ProgramDayStats;
