import { LOGIN, LOG_OUT } from "../actionTypes";

const initialState = {
    email: '',
    logged: false
};

export default function(state = initialState, action) {

    switch (action.type) {
        case LOGIN: {
            //const { newEmail, newName } = action.payload;
            const email = action.email;

            return {
                ...state,
                email: email,
                logged: true
            };
        }
        case LOG_OUT: {

            //Session.destroySession();

            return {
                ...state,
                email: '',
                logged: false
            };
        }

        default:
            return state;
    }
}

export const getUserEmail = state => state.user.email;
export const isUserLogged = state => state.user.logged;
