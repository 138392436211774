import React, {useState} from "react";
import {Button, Collapse} from "react-bootstrap";
import WorkoutSetGroup from "./WorkoutSetGroup";

import "../../assets/css/workouts/previousMove.css";
import DateTimeHelper from "../../helpers/DateTimeHelper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { faChevronRight, faChevronLeft, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RefValueBadge from "./RefValueBadge";
import _ from 'lodash';


function PreviousMoves(props) {
    const [open, setOpen] = useState(false);
    const [selectedMoveIndex, setSelectedMoveIndex] = useState( 0);

    const selectedMove = props.previousMoves[selectedMoveIndex] ?? {};

    return (
        <>
            <Col xs={{ span: 6, order: 2 }} md={{ span: 12, order: 3 }}>
                <Button
                    variant="outline-info"
                    className="icon-btn"
                    onClick={() => setOpen(!open)}
                    aria-controls={`last-set-${props.moveId}`}
                    aria-expanded={open}
                >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                </Button>
            </Col>
            <Col xs={{ span: 12, order: 4 }} md={{ span: 12, order: 4 }}>
                <Collapse in={open}>
                    <div id={`last-set-${props.moveId}`} className="p-3 last-move">
                        <Row>
                            <Col xs={6} className="mb-3">
                                <span className="mr-4 previous-move-selector" onClick={() => setSelectedMoveIndex(
                                    selectedMoveIndex-1 >= 0 ? selectedMoveIndex-1 : 0
                                )}>
                                     <FontAwesomeIcon icon={faChevronLeft} />
                                </span>

                                <span className="mr-4">
                                    {selectedMoveIndex + 1} / {props.previousMoves.length}
                                </span>

                                <span className="previous-move-selector" onClick={() => setSelectedMoveIndex(
                                    selectedMoveIndex+1 < props.previousMoves.length ? selectedMoveIndex+1 : selectedMoveIndex
                                )}>
                                     <FontAwesomeIcon icon={faChevronRight} />
                                </span>
                            </Col>
                            <Col xs={6} className="text-right">
                                <RefValueBadge
                                    previousMoves={_.slice(props.previousMoves, selectedMoveIndex + 1) ?? {}}
                                    refValue={selectedMove.ref_value}
                                    currentSetGroups={selectedMove.set_groups}
                                    programId={selectedMove.program_id}
                                />
                            </Col>
                            <Col xs={12} md={10}>
                                {selectedMove.comments && (
                                    <div className="mb-3">{selectedMove.comments}</div>
                                )}

                                {selectedMove.set_groups && selectedMove.set_groups.map((setGroup) => {
                                    return (
                                        <WorkoutSetGroup
                                            key={setGroup.group}
                                            group={setGroup.group}
                                            moveId={props.moveId}
                                            sets={setGroup.sets}
                                            showButtons={false}
                                        />
                                    )
                                })}
                            </Col>
                            <Col xs={12} md={2} className="text-md-right text-muted line-height-1">
                                <small>{DateTimeHelper.formatDate(selectedMove.date)}</small>

                                {selectedMove.program_name && (
                                    <div>
                                        <small>{selectedMove.program_name}</small>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Collapse>
            </Col>
        </>
    );
}

export default PreviousMoves;
