import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form'
import UserHelper from "../../helpers/userHelper";
import {Alert} from "react-bootstrap";
import ContentHeader from "../ContentHeader";

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            showError: false
        }

        this.handleUserInput = this.handleUserInput.bind(this);
        this.login = this.login.bind(this);
    }

    handleUserInput(e) {
        const name = e.target.name;
        let value = e.target.value.trim();

        this.setState({
           [name]: value
        })
    }

    login() {
        const logged = UserHelper.login(
            this.state.email,
            this.state.password
        );

        logged.then(function (response) {
            // TODO


        }.bind(this))
            .catch(error => {
                this.setState({showError: true})
            })

    }

    render() {
        return (
            <Row className="mb-5">

                <ContentHeader title="Kirjautuminen" />

                {this.state.showError && (
                    <Col xs={12} className="mb-4">
                        <Alert variant="danger">
                            Kirjautuminen epäonnistui.
                        </Alert>
                    </Col>
                )}
                        <Col xs={12} md={2}>
                            <Form.Label>
                                Käyttäjätunnus:
                            </Form.Label>
                        </Col>
                        <Col xs={12} md={10}>
                            <Form.Control
                                required
                                type="text"
                                name="email"
                                onChange={this.handleUserInput} />
                        </Col>


                <Col xs={12} md={2} className="mt-3">
                        <Form.Label>
                            Salasana:
                        </Form.Label>
                </Col>
                <Col xs={12} md={10} className="mt-3">
                        <Form.Control
                            required
                            type="password"
                            name="password"
                            onChange={this.handleUserInput} />
                </Col>

                <Col md={9}></Col>

                <Col xs={12} md={3} className="mt-5 mb-5">
                    <button className="btn btn-block btn-info"
                            onClick={this.login}>
                        Kirjaudu
                    </button>
                </Col>
            </Row>
        )
    }
}

export default Login;