import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Button, Form} from "react-bootstrap";
import MoveHelper from "../../../helpers/MoveHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import SetForm from "./SetForm";
import _ from 'lodash';
import ProgramMoveHelper from "../../../helpers/programs/ProgramMoveHelper";
import MoveSelector from "../../workouts/MoveSelector";

class ProgramMoveForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            comments: '',
            errors: {},
            move: '',
            moves: [],
            nroOfNewSets: 0,
            rest: '',
            sets: []
        };

        this.addSet = this.addSet.bind(this);
        this.editSet = this.editSet.bind(this);
        this.deleteSet = this.deleteSet.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);
        this.saveMove = this.saveMove.bind(this);
        this.selectMove = this.selectMove.bind(this);
    }

    componentDidMount() {
        // Get moves
        MoveHelper.getMoves().then(function (moves) {
            // Select first move

            let selectedMove = '';

            if (moves[0]) {
                selectedMove = moves[0].id;
            }

            this.setState({
                moves,
                move: selectedMove
            }, this.loadData)

        }.bind(this))
            .catch(error => {
                 this.props.showError('Harjoitusliikkeitä ei löytynyt.', false);
            })
    }

    loadData() {
        // Get move and sets
        if (this.props.loadData) {
            ProgramMoveHelper.getMove(
                this.props.programId,
                this.props.dayId,
                this.props.moveId
            ).then(function (m) {
                let comments = '';

                if (m.comments) {
                    comments = m.comments;
                }

                const newSets = m.sets.map((set) => {
                   return {
                       id: set.id,
                       times: set.times,
                       min: set.reps_min,
                       max: set.reps_max,
                       errors: {}
                   }
                });

                this.setState({
                    comments,
                    move: m.move_id,
                    rest: m.rest,
                    sets: newSets
                })

            }.bind(this))
                .catch(error => {
                    this.props.showError('Harjoitusliikettä ei löytynyt.', false);
                })
        }
    }

    addSet() {
        const setNumber = this.state.nroOfNewSets + 1;

        let sets = this.state.sets;

        const newSet = {
            id: `new-${setNumber}`,
            times: '',
            min: '',
            max: '',
            errors: {}
        };

        sets.push(newSet);

        this.setState({
            sets,
            nroOfNewSets: setNumber
        });
    }

    editSet(id, name, value) {

        let sets = this.state.sets;

        let setIndex = _.findIndex(sets, function(set) {
            return set.id === id;
        });

       /* let selectedSet = _.find(sets, function(set) {
            return set.id === id;
        });*/

        let selectedSet = sets[setIndex];

        selectedSet[name] = value;
        sets[setIndex] = selectedSet;

        this.setState({
            sets
        })
    }

    deleteSet(id) {
        const sets = this.state.sets;
        const newSets = _.filter(sets, function(set) { return set.id !== id; });

        this.setState({sets: newSets});
    }

    handleUserInput(e) {
        const name = e.target.name;
        let value = e.target.value;

        this.setState({
            [name]: value
        })
    }

    saveMove() {
        if (this.validate()) {
            this.props.saveMove(
                this.state.move,
                this.state.rest,
                this.state.comments.trim(),
                this.state.sets
            );
        }
    }

    selectMove(id) {
        this.setState({
            move: id
        })
    }

    validate() {

        const move = parseInt(this.state.move);

        let rest = this.state.rest;

        if (typeof rest === "string") {
            rest = rest.trim();
        }

        rest = parseInt(rest);
        this.setState({rest});

        const errors = this.state.errors;

        if (!Number.isInteger(move) || move < 1) {
            errors.move = 'Liike puuttuu.';

            this.setState({
                errors
            })

            return false;

        } else if (!Number.isInteger(rest) || rest < 1) {
            errors.rest = 'Anna sarjatauko numeroina.';

            this.setState({
                errors
            })

            return false;
        }

        // Validate sets
        let sets = this.state.sets;
        for (let i in sets) {
            let set = sets[i];
            let setErrors = {};

            if (!(Number.isInteger(set.id) || set.id.startsWith("new-"))) {
                this.props.showError("Tallennus epäonnistui", true);
               return false;
            }

            if (!Number.isInteger(set.times) || set.times < 1) {
                setErrors.times = "Anna kertojen määrä numerona.";
            }

            if (!Number.isInteger(set.min) || set.min < 1) {
                setErrors.min = "Anna minimimäärä numerona.";
            }

            if (!Number.isInteger(set.max) || set.max < 1) {
                setErrors.max = "Anna maksimimäärä numerona.";
            }

            set.errors = setErrors;

            if (!_.isEmpty(setErrors)) {
                this.setState({sets});
                return false;
            }

        }

        return true;
    }

    render() {

        // Sets
        const sets = this.state.sets.map((set) => {

            return (
                <SetForm
                    id={set.id}
                    times={set.times}
                    min={set.min}
                    max={set.max}
                    key={set.id}
                    errors={set.errors}
                    editSet={this.editSet}
                    deleteSet={this.deleteSet} />
            )
        });

        return (
            <Row>

                    <Col xs={12} className="mt-3">
                        <Form>
                            <Row>
                                <Col xs={12} md={8}>

                                    <MoveSelector
                                        error={this.state.errors.move}
                                        selectedMove={this.state.move}
                                        selectMove={this.selectMove}
                                    />

                                    {/*<Form.Group controlId="ProgramMove">
                                        <Form.Label>
                                            Liike *
                                        </Form.Label>
                                        <Form.Control as="select"
                                                        name="move"
                                                        value={this.state.move}
                                                      onChange={this.handleUserInput}>
                                            { moves }
                                        </Form.Control>

                                        {this.state.errors.move && (
                                            <Form.Text className="text-muted">
                                                {this.state.errors.move}
                                            </Form.Text>
                                        )}
                                    </Form.Group>*/}
                                </Col>

                                <Col xs={12} md={4}>
                                    <Form.Group controlId="ProgramMoveRest">
                                        <Form.Label>
                                            Sarjatauko (s) *
                                        </Form.Label>
                                        <Form.Control type="text"
                                                      name="rest"
                                                      value={this.state.rest}
                                                      maxLength="4"
                                                      onChange={this.handleUserInput}/>

                                        {this.state.errors.rest && (
                                            <Form.Text className="text-muted">
                                                {this.state.errors.rest}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>

                                <Col xs={12}>
                                    <Form.Group controlId="ProgramMoveComments">
                                        <Form.Label>
                                            Kommentit
                                        </Form.Label>
                                        <Form.Control type="text"
                                                      name="comments"
                                                      value={this.state.comments}
                                                      maxLength="255"
                                                      onChange={this.handleUserInput}/>

                                        {this.state.errors.comments && (
                                            <Form.Text className="text-muted">
                                                {this.state.errors.comments}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mt-5">
                                <Col xs={10}>
                                    <h5>Toistot</h5>
                                </Col>
                                <Col xs={2} className="text-right">
                                    <Button variant="light"
                                            className="icon-btn"
                                            onClick={this.addSet}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </Col>

                                <Col xs={12} className="mt-5">
                                    {sets}
                                </Col>
                            </Row>

                            <Row className="mt-5">
                                <Col xs={6} md={3}>
                                    <Button variant="default"
                                            className="btn-block"
                                            onClick={this.props.historyBack}>
                                        Peruuta
                                    </Button>
                                </Col>
                                <Col xs={6} md={3}>
                                    <Button variant="info"
                                            className="btn-block"
                                            onClick={this.saveMove}>
                                        Tallenna
                                    </Button>
                                </Col>
                            </Row>

                        </Form>

                    </Col>

            </Row>
        )
    }
}

export default ProgramMoveForm;