import {axiosInstance as axios} from '../../axiosConfig';
import AxiosHelper from './../axiosHelper';


class ProgramHelper {

    static getProgram(id) {
        return new Promise(function (resolve, reject) {
            try {
                axios.get("programs/" + id)
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    static async getProgramMovesAndWorkouts(programId) {
        try {
            const workouts = await axios.get(`programs/${programId}/workouts`, {});
            return workouts.data;
        } catch (e) {
            AxiosHelper.handleRequestError(e);
            throw e;
        }
    }

    static getPrograms() {
        return new Promise(function (resolve, reject) {
            try {
                axios.get("programs")
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    static createProgram(name, description) {
        return new Promise(function (resolve, reject) {
            try {
                axios.post("programs", {
                    name,
                    description
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    static updateProgram(id, name, description) {
        return new Promise(function (resolve, reject) {
            try {
                axios.put("programs/" + id, {
                    name,
                    description
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }
}

export default ProgramHelper;