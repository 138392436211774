import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import WorkoutSetGroup from "./WorkoutSetGroup";
import {
    faArrowsAltV,
    faPencilAlt,
    faPlus,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Dropdown} from "react-bootstrap";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import PreviousMoves from "./PreviousMoves";
import "../../assets/css/workouts/gymWorkout.css";
import RefValueBadge from "./RefValueBadge";

function WorkoutMove(props) {
    // Sortable
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: props.id});

    const sortableStyle = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    let setGroups = "";

    if (props.setGroups) {
        setGroups = props.setGroups.map((setGroup) => {
            return (
                <WorkoutSetGroup
                    key={setGroup.group}
                    group={setGroup.group}
                    moveId={props.id}
                    sets={setGroup.sets}
                    showSetForm={props.showSetForm}
                    showButtons={true}
                    deleteSet={props.deleteSet}
                />
            )
        })
    }

    const groupRowClass = props.setGroupNro > 0 ? 'superset-' + props.setGroupNro : '';

    const toggleOpen = () => {
        props.setIsOpen(props.id, !props.isOpen);
    }

    return (
        <Row className={`pt-2 pb-4 mb-1 ${groupRowClass}`} ref={setNodeRef} style={sortableStyle}>
            <Col xs={{span: 12, order: 1}} md={{span: 9, order: 1}}>
                <Row>
                    {/* <Col xs={1}>
                         <span
                             className="move-group-number" {...listeners} {...attributes}>{props.order}
                         </span>
                    </Col> */}
                    <Col xs={8} lg={9}>
                        <h6>
                            <span className="clickable" onClick={toggleOpen}>
                                {props.order}. {props.name}
                            </span>
                            <div className="mt-2 d-block d-sm-none"></div>
                        </h6>
                    </Col>
                    <Col xs={4} lg={3} className="text-small text-right">
                        <RefValueBadge
                            previousMoves={props.previousMoves}
                            refValue={props.refValue}
                            currentSetGroups={props.setGroups}
                            programId={props.programId}
                        />
                    </Col>
                </Row>

                {props.isOpen && (
                    <>
                        {props.programComments && (
                            <div className="mb-2">
                                <small>
                                    {props.programComments}
                                </small>
                            </div>
                        )}

                        {props.comments && (
                            <div className="mb-2">
                                <small>
                                    {props.comments}
                                </small>
                            </div>
                        )}

                    </>
                )}
            </Col>

            {props.isOpen && (
                <>
                    <Col xs={{span: 6, order: 3}} md={{span: 3, order: 2}} className="text-right">
                        <Button variant="light"
                                className="icon-btn mr-2"
                                onClick={() => props.showMoveForm(false, props.id)}>
                            <FontAwesomeIcon icon={faPencilAlt}/>
                        </Button>
                        <Button variant="light"
                                className="icon-btn mr-2"
                                onClick={() => props.showSetForm(props.id, 0)}>
                            <FontAwesomeIcon icon={faPlus}/>
                        </Button>

                        <Dropdown className="nav-btn">
                            <Dropdown.Toggle variant="light" id="dropdown-basic" className="icon-btn">
                                {/*<FontAwesomeIcon icon={faEllipsisV}/>*/}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {/* Show button if is not last move and this and/or next move dont have group or they are in the same group */}
                                {(props.order < props.nroOfMoves && !props.nextMoveInDifferentGroup && props.firstOrLast) && (
                                    <Dropdown.Item onClick={() => props.toggleLinkWithNextMove(props.id)}>
                                        <FontAwesomeIcon icon={faArrowsAltV}/> Linkitä
                                    </Dropdown.Item>
                                )}

                                <Dropdown.Item
                                    className="text-danger"
                                    onClick={() => props.deleteMove(props.id)}>
                                    <FontAwesomeIcon icon={faTrash}/> Poista
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>

                    {props.previousMoves && props.previousMoves.length > 0 ? (
                        <PreviousMoves moveId={props.id} previousMoves={props.previousMoves} />
                    ) : (
                        <Col xs={{span: 6, order: 2}} md={{span: 12, order: 3}}></Col>
                    )}

                    <Col xs={{span: 12, order: 5}} md={{span: 12, order: 5}} className="mt-4">
                        {setGroups}
                    </Col>
                </>
            )}
        </Row>
    )
}

export default WorkoutMove;