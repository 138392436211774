import React from 'react'
import {Route, Redirect } from "react-router-dom"
import { connect } from "react-redux";
import { isUserLogged } from "../redux/reducers/user";

function PrivateRoute({component: Component, logged, ...rest}) {
    return (
        <Route {...rest} render={props => (
            logged ?
                <Component {...props} {...rest} />
                : <Redirect to="/" />
        )} />
    )
};

function mapStateToProps(state) {
    return {
        logged: isUserLogged(state)
    }
}

export default connect(mapStateToProps)(PrivateRoute)