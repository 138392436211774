import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPencilAlt, faStopwatch} from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";

class WorkoutProgramMove extends React.Component {

    render() {
        const supersetRowClass = this.props.supersetGroupNro > 0 ? 'superset-' + this.props.supersetGroupNro : '';

        const sets = this.props.move.sets.map((set) => {
            return (
                <div key={set.id} className="text-small">
                    {set.times} x {set.reps_min}-{set.reps_max}
                </div>
            )
        });

        return (
            <Row className={`mt-3 p-1 ${supersetRowClass}`}>
                <Col xs={10} md={11}>
                    <div>
                        {this.props.order}. {this.props.move.move_name}
                    </div>
                    <div className="text-small mt-2">
                        <FontAwesomeIcon icon={faStopwatch}/> {this.props.move.rest}s
                    </div>
                </Col>

                <Col xs={2} md={1}>
                    <Button variant="light"
                            className="icon-btn float-right">
                        <Link to={`/programs/${this.props.programId}/days/${this.props.dayId}/move/${this.props.move.id}/edit`}>
                            <FontAwesomeIcon icon={faPencilAlt} />
                        </Link>
                    </Button>
                </Col>

                <Col xs={12}>
                    {sets}
                </Col>

                {this.props.move.comments && (
                    <Col xs={12} className="text-small mt-1">
                        {this.props.move.comments}
                    </Col>
                )}
            </Row>
        )
    }
}

export default WorkoutProgramMove;