import React, {useEffect, useRef} from 'react';
import {Form} from "react-bootstrap";

const ResizingTextarea = (props) => {
    //const [rows, setRows] = useState(1);
    const textareaRef = useRef(null);

    useEffect(() => {
        //let textRowCount = props.value? props.value.split("\n").length : 0

        textareaRef.current.style.height = "0px";
        const scrollHeight = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = `${scrollHeight + 2}px`;

        /*if (textRowCount > 0) {
            setRows(textRowCount);
        }*/
    }, [props.value]);

    return (
        <Form.Group controlId="">
            <Form.Label>
                {props.title}
            </Form.Label>
            <Form.Control as="textarea"
                          ref={textareaRef}
                          name={props.name}
                          value={props.value}
                          onChange={props.handleChange}/>
        </Form.Group>
    );
};

export default ResizingTextarea;