import React from 'react';
import _ from 'lodash';
import {faWeightHanging} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import WorkoutHelper from "../../helpers/workoutHelper";

function RefValueBadge(props) {
    // Current sets for reps calculation
    const currentSets = _.flatten(_.map(props.currentSetGroups, 'sets'));
    // Current sets for weight calculation
    // (if same weight multiple times in the same set -> calculate weight only once (needed for clusters))
    const currentSetsForWeight = _.uniqWith(currentSets, WorkoutHelper.compareSets);

    let currentReps = 0;
    let currentWeights = 0;

    currentSets.forEach(item => {
        currentReps += Number(item.reps);
    });

    currentSetsForWeight.forEach(item => {
        currentWeights += Number(item.weight)
    });

    let previousReps = 0;
    let previousWeights = 0;

    const latestMove = _.find(props.previousMoves, { 'program_id': props.programId });

    if (latestMove) {
        // Latest sets for reps calculation
        const latestSets = _.flatten(_.map(latestMove.set_groups, 'sets'));
        // Latest sets for weight calculation
        const latestSetsForWeight = _.uniqWith(latestSets, WorkoutHelper.compareSets);

        latestSets.forEach(item => {
            previousReps += Number(item.reps);
        });

        latestSetsForWeight.forEach(item => {
            previousWeights += Number(item.weight)
        });
    }

    const calcPercentDiff = (previous, current) => {
        let diff = 0;

        if (previous > 0) {
            diff = (current / previous - 1) * 100;
        }

        return diff.toFixed(1);
    };

    const calcAmountDiff = (previous, current) => {
        return current - previous;
    };

    const getBadgeClass = (diff) => {
        if (diff == 0) {
            return 'info';
        }

        return diff >= 0 ? 'success' : 'danger';
    };

    const weightDiff = calcPercentDiff(previousWeights, currentWeights);
    const repsDiff = calcAmountDiff(previousReps, currentReps);

    return (
        <>
            {/*<span className={`ml-2 badge badge-pill badge-${refValueBadgeClass}`}>
                {props.refValue}
            </span>*/}

            {(previousReps > 0 && props.currentSetGroups.length > 0) && (
                <>
                     <span className={`ml-2 text-${getBadgeClass(weightDiff)}`}>
                        <FontAwesomeIcon icon={faWeightHanging}/>
                        <span className="ml-1">
                            {weightDiff > 0 ? '+' : ''}{weightDiff} %
                        </span>
                    </span>
                    <span className={`ml-2 text-${getBadgeClass(repsDiff)}`}>

                        <span className="ml-1">
                            {repsDiff > 0 ? '+' : ''}{repsDiff}
                        </span>
                    </span>
                </>
            )}
        </>
    );
}

export default RefValueBadge;