import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ProgramHelper from "../../helpers/programs/ProgramHelper";
import {Alert, Button} from "react-bootstrap";
import WorkoutProgram from "./WorkoutProgram";
import {Link} from "react-router-dom";
import ContentHeader from "../ContentHeader";

class WorkoutPrograms extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: '',
            programs: []
        }
    }

    componentDidMount() {

        ProgramHelper.getPrograms().then(function (programs) {
            this.setState({
                programs
            })

        }.bind(this))
            .catch(error => {
               this.setState({error: 'Ohjelmien haku epäonnistui.'})
            })


    }

    render() {
        /*const programs = this.state.programs.map((item) => {
            return (
                <Col xs={12} key={item.id}>
                    <Link to={`/programs/${item.id}`}>
                        {item.name}
                    </Link>
                </Col>
            )
        })*/

        const programs = this.state.programs.map((item) => {
            return (
                <WorkoutProgram
                    key={item.id}
                    program={item} />
            )
        })


        return (
            <Row className="mb-5">
                <ContentHeader title="Saliohjelmat" />

                <Col xs={12} className="text-right">
                    <Link to="/programs/new">
                        <Button variant="light">
                            Luo uusi ohjelma
                        </Button>
                    </Link>
                </Col>

                {/* TODO: joku viesti kun tullaan uuden luonnista */}

                {this.state.error ? (
                    <Col xs={12}>
                        <Alert variant="danger">
                            {this.state.error}
                        </Alert>
                    </Col>
                ) : (

                    <Col xs={12}>
                        {programs}
                    </Col>
                )}

            </Row>
        )
    }
}

export default WorkoutPrograms;