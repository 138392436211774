import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Button, Form} from "react-bootstrap";
import ProgramHelper from "../../helpers/programs/ProgramHelper";
import ResizingTextarea from "../Forms/ResizingTextarea";

class WorkoutProgramForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            name: '',
            nameError: '',
            description: '',
            showForm: true
        };

        this.handleUserInput = this.handleUserInput.bind(this);
        this.saveProgram = this.saveProgram.bind(this);
    }

    componentDidMount() {
        if (this.props.loadData) {
            if (this.props.id) {
                ProgramHelper.getProgram(this.props.id).then(function (program) {
                    this.setState({
                        loaded: true,
                        name: program.name,
                        description: program.description
                    })

                }.bind(this))
                    .catch(error => {
                        this.setState({loaded: true, showForm: false});
                        this.props.showError('Ohjelmaa ei löytynyt.');
                    })
            } else {
                this.setState({loaded: true, showForm: false});
                this.props.showError('Ohjelmaa ei löytynyt.');
            }
        } else {
            this.setState({loaded: true});
        }
    }

    handleUserInput(e) {
        const name = e.target.name;
        let value = e.target.value;

        this.setState({
            [name]: value
        })
    }

    saveProgram() {
        if (this.validate()) {
            this.props.saveProgram(
                this.state.name ? this.state.name.trim() : '',
                this.state.description ? this.state.description.trim() : ''
            );
        }
    }

    validate() {

        const name = this.state.name.trim();

        if (name.length < 1) {
            this.setState({
                nameError: 'Nimi puuttuu.'
            })

            return false;
        }

        return true;
    }

    render() {

        return (
            <Row>

                {(this.state.showForm && this.state.loaded) && (
                    <Col xs={12}>
                        <Form>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group controlId="newProgramName">
                                        <Form.Label>
                                            Nimi *
                                        </Form.Label>
                                        <Form.Control type="text"
                                                      name="name"
                                                      value={this.state.name}
                                                      maxLength="50"
                                                      onChange={this.handleUserInput}/>

                                        {this.state.nameError && (
                                            <Form.Text className="text-muted">
                                                {this.state.nameError}
                                            </Form.Text>
                                        )}
                                    </Form.Group>

                                    <ResizingTextarea
                                        title="Kuvaus"
                                        name="description"
                                        value={this.state.description}
                                        handleChange={this.handleUserInput}
                                    />
                                </Col>
                            </Row>

                            <Row className="mt-4">

                                <Col xs={6} md={3}>
                                    <Button variant="default"
                                            className="btn-block"
                                            onClick={this.props.historyBack}>
                                        Peruuta
                                    </Button>
                                </Col>
                                <Col xs={6} md={3}>
                                    <Button variant="info"
                                            className="btn-block"
                                            onClick={this.saveProgram}>
                                        {this.props.saveBtnText}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                )}

                {(!this.state.showForm && this.state.loaded) && (
                    <Col xs={12} md={3} className="mt-4">
                        <Button variant="default"
                                className="btn-block"
                                onClick={this.props.historyBack}>
                            Peruuta
                        </Button>
                    </Col>
                )}

            </Row>
        )
    }
}

export default WorkoutProgramForm;