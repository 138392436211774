import React from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faTrash} from "@fortawesome/free-solid-svg-icons";

class WorkoutSetGroup extends React.Component {

    render() {
        let sets = "";

        if (this.props.sets) {
            const nroOfSets = this.props.sets.length;

            sets = this.props.sets.map((set, index) => {
                return (
                    <span key={set.id}>
                        {set.weight}kg {set.reps}
                        {index < (nroOfSets-1) && (
                            <span className="mr-2 ml-2">|</span>
                        )}
                    </span>
                )
            })
        }

        return (
            <Row className="mb-2">
                <Col xs={1}>
                      <span className="mr-3">
                        {this.props.group}
                    </span>
                </Col>

                <Col xs={7} md={9}>
                    {sets}
                </Col>

                {this.props.showButtons && (
                    <Col xs={4} md={2} className="text-right">
                        <Button variant="light"
                                className="icon-btn"
                                onClick={() => this.props.showSetForm(this.props.moveId, this.props.group)}>
                            <FontAwesomeIcon icon={faPencilAlt} />
                        </Button>

                        <Button variant="danger"
                                className="icon-btn ml-2"
                                onClick={() => this.props.deleteSet(this.props.moveId, this.props.group)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </Col>
                )}
            </Row>
        )
    }
}

export default WorkoutSetGroup;