import {axiosInstance as axios} from '../../axiosConfig';
import AxiosHelper from './../axiosHelper';


class BodyStatsHelper {

    static getBodyStats(token) {
        return new Promise(function (resolve, reject) {
            try {
                axios.get("bodystats", {
                    params: {
                        token
                    }
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }
}

export default BodyStatsHelper;