import {axiosInstance as axios} from '../../axiosConfig';
import AxiosHelper from './../axiosHelper';


class ProgramMoveHelper {

    static getMove(programId, dayId, moveId) {

        return new Promise(function (resolve, reject) {
            try {
                // Remove new- id

                axios.get("programs/" + programId + "/days/" + dayId + "/moves/" + moveId, {
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    static createMove(programId, dayId, move, rest, comments, sets) {

        return new Promise(function (resolve, reject) {
            try {
                // Remove new- id
                let newSets = sets.map((s) => {
                    delete s.id;
                    return s;
                });

                axios.post("programs/" + programId + "/days/" + dayId + "/moves", {
                    move,
                    rest,
                    comments,
                    sets: newSets
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    static updateMove(programId, dayId, programMoveId, move, rest, comments, sets) {

        return new Promise(function (resolve, reject) {
            try {
                // Remove new- id
                let newSets = sets.map((s) => {
                    if (typeof s.id === "string" && s.id.startsWith("new-")) {
                        delete s.id;
                    }

                    return s;
                });

                axios.put("programs/" + programId + "/days/" + dayId + "/moves/" + programMoveId, {
                    move,
                    rest,
                    comments,
                    sets: newSets
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }
}

export default ProgramMoveHelper;