import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DateTimeHelper from "../../helpers/DateTimeHelper";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RPEBadge from "./rpe/RPEBadge";

class GymWorkoutTile extends React.Component {

    render() {

        return (
            <Row className="mt-3 mb-3">
                <Col xs={9}>
                    <div className="text-muted text-small">
                        {DateTimeHelper.formatDate(this.props.date)}
                    </div>
                    <Link to={`/workouts/${this.props.id}`} className="font-weight-bold text-info">
                        {this.props.title}
                    </Link>
                    <br/>
                    <div className="text-muted text-small">
                        {this.props.programName}
                    </div>
                    <div className="mt-1">
                        <RPEBadge value={this.props.rpe} />
                    </div>
                </Col>
                <Col xs={3} className="text-right">
                    <Button variant="danger"
                            className="icon-btn"
                            onClick={() => this.props.deleteWorkout(this.props.id)}>
                        <FontAwesomeIcon icon={faTrash}/>
                    </Button>
                </Col>

            </Row>
        )
    }
}

export default GymWorkoutTile;