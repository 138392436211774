import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import _ from 'lodash';
import DateTimeHelper from "../../helpers/DateTimeHelper";

import {isMonday, isTuesday, isWednesday, isThursday, isFriday, isSaturday, isSunday} from 'date-fns';

import './../../assets/css/workout.css';
import './../../assets/css/weekCalendar.css';
import WorkoutMobile from "./WorkoutMobile";
import RestDay from "./RestDay";

class WeekCalendar extends React.Component {

    componentDidMount() {
    }

    render() {

        const monday = _.filter(this.props.workouts, function(w) {
            return isMonday(new Date(w.date));
        });

        const tuesday = _.filter(this.props.workouts, function(w) {
            return isTuesday(new Date(w.date));
        });

        const wednesday = _.filter(this.props.workouts, function(w) {
            return isWednesday(new Date(w.date));
        });

        const thursday = _.filter(this.props.workouts, function(w) {
            return isThursday(new Date(w.date));
        });

        const friday = _.filter(this.props.workouts, function(w) {
            return isFriday(new Date(w.date));
        });

        const saturday = _.filter(this.props.workouts, function(w) {
            return isSaturday(new Date(w.date));
        });

        const sunday = _.filter(this.props.workouts, function(w) {
            return isSunday(new Date(w.date));
        });

        // Desktop

        /*const mondayWorkouts = monday.map((item) => {
            return (
                <WorkoutDesktop workout={item} />
            )
        });

        const tuesdayWorkouts = tuesday.map((item) => {
            return (
                <WorkoutDesktop workout={item} />
            )
        });

        const wednesdayWorkouts = wednesday.map((item) => {
            return (
                <WorkoutDesktop workout={item} />
            )
        });

        const thursdayWorkouts = thursday.map((item) => {
            return (
                <WorkoutDesktop workout={item} />
            )
        });

        const fridayWorkouts = friday.map((item) => {
            return (
                <WorkoutDesktop workout={item} />
            )
        });

        const saturdayWorkouts = saturday.map((item) => {
            return (
                <WorkoutDesktop workout={item} />
            )
        });

        const sundayWorkouts = sunday.map((item) => {
            return (
                <WorkoutDesktop workout={item} />
            )
        });*/

        // Mobile

        const mondayWorkoutsM = monday.map((item) => {
            return (
                <WorkoutMobile workout={item} key={item.id} />
            )
        });

        const tuesdayWorkoutsM = tuesday.map((item) => {
            return (
                <WorkoutMobile workout={item} key={item.id} />
            )
        });

        const wednesdayWorkoutsM = wednesday.map((item) => {
            return (
                <WorkoutMobile workout={item} key={item.id} />
            )
        });

        const thursdayWorkoutsM = thursday.map((item) => {
            return (
                <WorkoutMobile workout={item} key={item.id} />
            )
        });

        const fridayWorkoutsM = friday.map((item) => {
            return (
                <WorkoutMobile workout={item} key={item.id} />
            )
        });

        const saturdayWorkoutsM = saturday.map((item) => {
            return (
                <WorkoutMobile workout={item} key={item.id} />
            )
        });

        const sundayWorkoutsM = sunday.map((item) => {
            return (
                <WorkoutMobile workout={item} key={item.id} />
            )
        });


        return (
            <div>
            <Row>
                {/*<Col xs={12}>
                    <div className="weekday">
                        Maanantai<br/>
                        {DateTimeHelper.formatDate(this.props.startDate)}
                    </div>
                    <div className="weekday">
                        Tiistai<br/>
                        {DateTimeHelper.addDates(this.props.startDate, 1)}
                    </div>
                    <div className="weekday">
                        Keskiviikko<br/>
                        {DateTimeHelper.addDates(this.props.startDate, 2)}
                    </div>
                    <div className="weekday">
                        Torstai<br/>
                        {DateTimeHelper.addDates(this.props.startDate, 3)}
                    </div>
                    <div className="weekday">
                        Perjantai<br/>
                        {DateTimeHelper.addDates(this.props.startDate, 4)}
                    </div>
                    <div className="weekday">
                        Lauantai<br/>
                        {DateTimeHelper.addDates(this.props.startDate, 5)}
                    </div>
                    <div className="weekday">
                        Sunnuntai<br/>
                        {DateTimeHelper.addDates(this.props.startDate, 6)}
                    </div>
                </Col>

                <Col xs={12}>
                    <div className="days-workouts">
                        {mondayWorkouts}
                    </div>
                    <div className="days-workouts">
                        {tuesdayWorkouts}
                    </div>
                    <div className="days-workouts">
                        {wednesdayWorkouts}
                    </div>
                    <div className="days-workouts">
                        {thursdayWorkouts}
                    </div>
                    <div className="days-workouts">
                        {fridayWorkouts}
                    </div>
                    <div className="days-workouts">
                        {saturdayWorkouts}
                    </div>
                    <div className="days-workouts">
                        {sundayWorkouts}
                    </div>
                </Col>*/}

                <Col xs={12} className="pt-5 pb-5 section-content">
                    <div>
                        <span className="weekday">
                            Maanantai {DateTimeHelper.formatDate(this.props.startDate)}
                        </span>

                        {mondayWorkoutsM.length > 0 ? (
                            mondayWorkoutsM
                        ) : (
                            <RestDay />
                        )}
                    </div>

                    <div className="mt-4">
                         <span className="weekday">
                            Tiistai {DateTimeHelper.addDates(this.props.startDate, 1)}
                         </span>

                        {tuesdayWorkoutsM.length > 0 ? (
                            tuesdayWorkoutsM
                        ) : (
                            <RestDay />
                        )}
                    </div>

                    <div className="mt-4">
                         <span className="weekday">
                            Keskiviikko {DateTimeHelper.addDates(this.props.startDate, 2)}
                         </span>

                        {wednesdayWorkoutsM.length > 0 ? (
                            wednesdayWorkoutsM
                        ) : (
                            <RestDay />
                        )}
                    </div>

                    <div className="mt-4">
                         <span className="weekday">
                            Torstai {DateTimeHelper.addDates(this.props.startDate, 3)}
                         </span>

                        {thursdayWorkoutsM.length > 0 ? (
                            thursdayWorkoutsM
                        ) : (
                            <RestDay />
                        )}
                    </div>

                    <div className="mt-4">
                         <span className="weekday">
                            Perjantai {DateTimeHelper.addDates(this.props.startDate, 4)}
                         </span>

                        {fridayWorkoutsM.length > 0 ? (
                            fridayWorkoutsM
                        ) : (
                            <RestDay />
                        )}
                    </div>

                    <div className="mt-4">
                         <span className="weekday">
                            Lauantai {DateTimeHelper.addDates(this.props.startDate, 5)}
                         </span>

                        {saturdayWorkoutsM.length > 0 ? (
                            saturdayWorkoutsM
                        ) : (
                            <RestDay />
                        )}
                    </div>

                    <div className="mt-4">
                         <span className="weekday">
                            Sunnuntai {DateTimeHelper.addDates(this.props.startDate, 6)}
                         </span>

                        {sundayWorkoutsM.length > 0 ? (
                            sundayWorkoutsM
                        ) : (
                            <RestDay />
                        )}
                    </div>
                </Col>
            </Row>
            </div>
        );
    }
}

export default WeekCalendar;