import './App.css';
import {BrowserRouter} from "react-router-dom";
import Content from './components/Content';
import Header from "./components/Header";
import Footer from "./components/Footer";
import React from "react";
import UserHelper from "./helpers/userHelper";
import store from "./redux/store";
import {loginUser} from "./redux/actions/user";

class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
           userLoaded: false
        };
    }

    componentDidMount() {
        const cookieSet = UserHelper.getCsrfCookie();

        cookieSet.then(function (res) {
            const userChecked = UserHelper.getUser();

            userChecked.then(function (user) {
                store.dispatch(loginUser(user.email));

                this.setState({userLoaded: true});

            }.bind(this))
                .catch(error => {
                    // TODO
                    this.setState({userLoaded: true});
                })

        }.bind(this))
            .catch(error => {
                // TODO
            })
    }

    render() {



  return (
      <div className="App">
        <BrowserRouter>

            <Header />
            {this.state.userLoaded && (
                <Content />
            )}

            <Footer />

        </BrowserRouter>
      </div>
  ); }
}

export default App;
