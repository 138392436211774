import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {Alert, Button} from "react-bootstrap";
import WorkoutHelper from "../../helpers/workoutHelper";
import DateTimeHelper from "../../helpers/DateTimeHelper";
import WorkoutMove from "./WorkoutMove";
import SetForm from "./SetForm";
import _ from "lodash";
import WorkoutSetHelper from "../../helpers/workouts/WorkoutSetHelper";
import ContentHeader from "../ContentHeader";
import WorkoutMoveForm from "./WorkoutMoveForm";
import WorkoutMoveHelper from "../../helpers/workouts/WorkoutMoveHelper";
import {DndContext} from "@dnd-kit/core";
import {SortableContext} from '@dnd-kit/sortable';
import RPESelector from "./rpe/RPESelector";
import {Link} from "react-router-dom";
import RouteHelper from "../../helpers/RouteHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartLine, faPlus} from "@fortawesome/free-solid-svg-icons";

class GymWorkout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            collapsedMoves: [], // can be removed if forms are moved to modals
            error: '',
            moveForm: {
                show: false,
                isNewMove: true,
                workoutMoveId: 0,
                moveId: 0,
                comments: ''
            },
            scrollPosY: '',
            setForm: {
                show: false,
                moveId: 0,
                setGroup: 0,
                setData: []
            },
            workout: []
        }

        this.showSetForm = this.showSetForm.bind(this);
        this.closeSetForm = this.closeSetForm.bind(this);
        this.showEditedSet = this.showEditedSet.bind(this);
        this.deleteSet = this.deleteSet.bind(this);

        this.showMoveForm = this.showMoveForm.bind(this);
        this.showEditedMove = this.showEditedMove.bind(this);
        this.closeMoveForm = this.closeMoveForm.bind(this);
        this.deleteMove = this.deleteMove.bind(this);

        this.toggleLinkWithNextMove = this.toggleLinkWithNextMove.bind(this);
        this.toggleMove = this.toggleMove.bind(this);

        this.handleDragEnd = this.handleDragEnd.bind(this);

        this.selectRPE = this.selectRPE.bind(this);
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            WorkoutHelper.getWorkout(this.props.match.params.id).then(function (workout) {
                this.setState({
                    workout
                })

            }.bind(this))
                .catch(error => {
                    this.setState({error: 'Treenin haku epäonnistui.'})
                })

        } else {
            this.setState({error: 'Treenin haku epäonnistui.'})
        }
    }

    setScrollPosition() {
        this.setState({
            scrollPosY: window.pageYOffset
        })
    }

    restoreScrollPosition() {
        window.scrollTo(0, this.state.scrollPosY);
    }

    toggleLinkWithNextMove(moveId) {
        const allMoves = this.state.workout.moves;
        const moveIndex1 = _.findIndex(allMoves, {'id': moveId});
        let linkGroups = true;

        if (moveIndex1 >= 0) {
            const move1 = allMoves[moveIndex1];

            if (allMoves[moveIndex1 + 1]) {
                const move2 = allMoves[moveIndex1 + 1];

                if (move1.set_group > 0 && move2.set_group > 0 &&
                    move2.set_group === move1.set_group) {
                    // Both moves are in the same group -> unlink
                    linkGroups = false;
                }
            }
        }

        if (linkGroups) {
            WorkoutMoveHelper.linkMove(
                this.state.workout.id,
                moveId
            ).then(function (moves) {
                this.showLinkUpdates(moves);
            }.bind(this))
                .catch(error => {
                })
        } else {
            WorkoutMoveHelper.unlinkMove(
                this.state.workout.id,
                moveId
            ).then(function (moves) {
                this.showLinkUpdates(moves);
            }.bind(this))
                .catch(error => {
                })
        }
    }

    /**
     * Show / hide move information
     * @param workoutMoveId
     * @param open
     */
    toggleMove(workoutMoveId, open) {
        let collapsedMoves = this.state.collapsedMoves;
        if (!open) {
            collapsedMoves.push(workoutMoveId);
        } else {
            collapsedMoves =_.filter(collapsedMoves, function(n) {
                return n != workoutMoveId;
            });
        }

        this.setState({collapsedMoves});
    }

    showLinkUpdates(moves) {
        if (this.state.workout) {
            let workout = this.state.workout;

            for (let m of moves) {
                const moveIndex = _.findIndex(this.state.workout.moves, {'id': m.id});

                if (moveIndex >= 0) {
                    // Update move group
                    workout.moves[moveIndex].set_group = m.group;
                }
            }

            this.setState({workout});
        }
    }

    showSetForm(moveId, setGroup) {
        this.setScrollPosition();
        let setData = [];

        if (this.state.workout) {
            const move = _.find(this.state.workout.moves, {'id': moveId});

            if (move && setGroup > 0) {
                const setGroups = _.find(move.set_groups, {group: setGroup});

                if (setGroups.sets) {
                    setData = [...setGroups.sets] // Deep copy
                }
            }
        }

        const setForm = {
            show: true,
            moveId,
            setGroup,
            setData
        }

        this.setState({
            setForm
        });
    }

    closeSetForm() {
        const setForm = {
            show: false,
            moveId: 0,
            setGroup: 0,
            setData: []
        }

        this.setState({
            setForm
        }, this.restoreScrollPosition);
    }

    showEditedSet(moveId, newSets, newRefValue) {
        let workout = this.state.workout;

        let moveIndex = _.findIndex(workout.moves, function (move) {
            return move.id === moveId;
        });

        workout.moves[moveIndex].set_groups = newSets;
        workout.moves[moveIndex].ref_value = newRefValue;

        this.setState({workout}, this.closeSetForm);
    }

    deleteSet(moveId, setGroup) {
        WorkoutSetHelper.deleteSet(
            this.state.workout.id,
            moveId,
            setGroup
        ).then(function (newSets) {
            this.showEditedSet(moveId, newSets.sets, newSets.ref_value);

        }.bind(this))
            .catch(error => {
                this.setState({error: 'Poisto epäonnistui.'})
            })
    }

    /* Moves */

    showMoveForm(isNewMove, workoutMoveId) {
        this.setScrollPosition();
        let comments = "";
        let moveId = 0;

        if (this.state.workout) {
            const moves = [...this.state.workout.moves]
            const move = _.find(moves, {'id': workoutMoveId});

            if (move) {
                comments = move.comments;
                moveId = move.move_id;
            }
        }

        const moveForm = {
            show: true,
            isNewMove: isNewMove,
            workoutMoveId,
            moveId: moveId,
            comments: comments
        }

        this.setState({
            moveForm
        });
    }

    closeMoveForm() {
        const moveForm = {
            show: false,
            isNewMove: true,
            workoutMoveId: 0,
            moveId: 0,
            comments: ''
        }

        this.setState({
            moveForm
        }, this.restoreScrollPosition);
    }

    showEditedMove(newMove) {
        if (this.state.workout) {
            const moveIndex = _.findIndex(this.state.workout.moves, {'id': newMove.id});
            let workout = this.state.workout;

            if (moveIndex >= 0) {
                // Update existing move
                workout.moves[moveIndex] = newMove;
            } else {
                // Add new
                workout.moves.push(newMove);
            }

            this.setState({workout}, this.closeMoveForm);
        }
    }

    deleteMove(moveId) {
        WorkoutMoveHelper.deleteMove(
            this.state.workout.id,
            moveId
        ).then(function (response) {
            let workout = this.state.workout;

            _.remove(workout.moves, function (item) {
                return item.id === moveId;
            });

            if (response.linkGroupDeleted) {
                _.forEach(workout.moves, function (item, key) {
                    if (item.set_group == response.linkGroup) {
                        item.set_group = 0;
                    }
                });
            }

            this.setState({workout});

        }.bind(this))
            .catch(error => {
                this.setState({error: 'Poisto epäonnistui.'})
            })
    }

    async handleDragEnd(event) {
        // If moving from back to front -> clicked item is before over item
        // If moving from front to back -> clicked item is after over item
        const clicked = event.active; // Clicked item
        const over = event.over; // Moving clicked item over this item
        const clickedIndex = clicked.data.current.sortable.index;
        const overIndex = over.data.current.sortable.index;

        let workout = this.state.workout;
        const moves = workout.moves;

        moves.map((item, index) => {
            item.order = index + 1;
            item.clicked = 0;
        });

        const clickedListIndex = _.findIndex(moves, ['id', clicked.id]);
        moves[clickedListIndex].order = clickedIndex < overIndex ? overIndex + 1 : overIndex;
        moves[clickedListIndex].clicked = 1;

        const overListIndex = _.findIndex(moves, ['id', over.id]);
        moves[overListIndex].order = overIndex + 1;

        // Sort items based on new order. If two items have the same order, non-clicked is first.
        const sortedItems = _.sortBy(moves, ['order', 'clicked']);

        sortedItems.map((item, index) => {
            //item.order = index + 1;
            delete item.order;
            delete item.clicked;
        });

        workout.moves = sortedItems;

        try {
            const clickedSortedIndex = _.findIndex(sortedItems, ['id', clicked.id]);

            await WorkoutMoveHelper.updateMoveOrder(
                this.state.workout.id,
                clicked.id,
                clickedSortedIndex + 1
            );
            this.setState({workout});
        } catch (e) {
        }
    }

    async selectRPE(value) {
        try {
            const updated = await WorkoutHelper.updateRPE(this.state.workout.id, value);

            let workout = this.state.workout;
            workout.rpe = value;
            this.setState({workout});
        } catch (e) {
        }
    }

    render() {

        let moves = "";

        if (this.state.workout.moves) {
            let groups = 0; // supersets
            let lastGroupNro = 0;
            const nroOfMoves = this.state.workout.moves.length;
            const allMoves = this.state.workout.moves;

            moves = allMoves.map((move, index) => {
                let setGroupNro = 0;
                let nextMoveInDifferentGroup = false;
                let firstOrLast = false;

                if (move.set_group > 0) {
                    if (move.set_group !== lastGroupNro) {
                        groups++;
                        lastGroupNro = move.set_group;
                    }

                    setGroupNro = groups;

                    // Is move first in group or next move last in group
                    const groupMoves = _.filter(allMoves, {'set_group': move.set_group});
                    const firstMove = _.head(groupMoves);
                    const lastMove = _.last(groupMoves);

                    if (firstMove.id === move.id) {
                        firstOrLast = true;
                    }

                    // Check if this move and next move are in different groups
                    if (allMoves[index + 1]) {
                        const nextMove = allMoves[index + 1];

                        if (lastMove.id === nextMove.id || lastMove.id === move.id) {
                            firstOrLast = true;
                        }

                        if (nextMove.set_group > 0 && nextMove.set_group !== move.set_group) {
                            // Next move has been added to a different group
                            nextMoveInDifferentGroup = true;
                        }
                    }
                } else {
                    firstOrLast = true;
                }

                return (
                    <WorkoutMove
                        key={move.id}
                        id={move.id}
                        order={index + 1}
                        name={move.name}
                        comments={move.comments}
                        setGroupNro={setGroupNro}
                        nroOfMoves={nroOfMoves}
                        programId={this.state.workout.program_id}
                        programComments={move.program_comments}
                        setGroups={move.set_groups}
                        lastMove={move.last_move}
                        previousMoves={move.previous_moves}
                        refValue={move.ref_value}
                        nextMoveInDifferentGroup={nextMoveInDifferentGroup}
                        firstOrLast={firstOrLast}
                        showMoveForm={this.showMoveForm}
                        showSetForm={this.showSetForm}
                        deleteSet={this.deleteSet}
                        deleteMove={this.deleteMove}
                        toggleLinkWithNextMove={this.toggleLinkWithNextMove}
                        setIsOpen={this.toggleMove}
                        isOpen={_.findIndex(this.state.collapsedMoves, function(c) {
                            return c === move.id;
                        }) === -1}
                    />
                )
            })
        }

        // Page title
        let pageTitle = "Salitreeni";

        if (this.state.workout.title) {
            pageTitle = this.state.workout.title;
        }

        return (
            <Row className="mb-5">

                {(this.state.setForm.show && !this.state.moveForm.show) && (
                    <Col xs={12}>
                        <SetForm
                            key={this.state.setForm.moveId}
                            workoutId={this.state.workout.id}
                            moveId={this.state.setForm.moveId}
                            setGroup={this.state.setForm.setGroup}
                            initialData={this.state.setForm.setData}
                            closeForm={this.closeSetForm}
                            showEditedSet={this.showEditedSet}
                        />
                    </Col>
                )}

                {(!this.state.setForm.show && this.state.moveForm.show) && (
                    <Col xs={12}>
                        <WorkoutMoveForm
                            workoutId={this.state.workout.id}
                            workoutMoveId={this.state.moveForm.workoutMoveId}
                            isNewMove={this.state.moveForm.isNewMove}
                            initialMoveId={this.state.moveForm.moveId}
                            initialComments={this.state.moveForm.comments}
                            closeForm={this.closeMoveForm}
                            showEditedMove={this.showEditedMove}
                        />
                    </Col>
                )}

                {(!this.state.setForm.show && !this.state.moveForm.show) && (
                    <Col xs={12}>
                        <Row>

                            <ContentHeader title={pageTitle}/>

                            {this.state.error ? (
                                <Col xs={12}>
                                    <Alert variant="danger">
                                        {this.state.error}
                                    </Alert>
                                </Col>
                            ) : (

                                <Col xs={12}>
                                    <Row>
                                        <Col xs={12} className="text-center mt-n5">
                                            {this.state.workout.date && (
                                                <span>
                                                {DateTimeHelper.formatDateTime(this.state.workout.date)}
                                            </span>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} className="text-muted">
                                            {this.state.workout.program_description && (
                                                this.state.workout.program_description.split("\n").map((i, key) => {
                                                    return <div key={key}>{i}</div>;
                                                }))}
                                        </Col>
                                    </Row>

                                    <Row className="mt-5">
                                        <Col xs={12}>
                                            <DndContext onDragEnd={this.handleDragEnd}>
                                                <SortableContext items={this.state.workout.moves ?? [] }>
                                                    {moves}
                                                </SortableContext>
                                            </DndContext>
                                        </Col>

                                        <Col xs={12} className="text-right">
                                            <Button variant="light"
                                                    onClick={() => this.showMoveForm(true, 0)}>
                                                <FontAwesomeIcon icon={faPlus} /> Lisää liike
                                            </Button>

                                            <Link to={RouteHelper.getProgramStatsUrl(this.state.workout.program_id)}
                                                className="ml-2">
                                                <Button variant="light">
                                                    <FontAwesomeIcon icon={faChartLine} /> Näytä tilastot
                                                </Button>
                                            </Link>
                                        </Col>
                                    </Row>

                                    <Row className="mt-5">
                                        <Col xs={12} className="text-center">
                                            <h6>Treenin kuormittavuus</h6>
                                        </Col>
                                        <Col xs={12}>
                                            <RPESelector
                                                selectedRPE={this.state.workout.rpe}
                                                selectRPE={this.selectRPE}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    </Col>
                )}


            </Row>
        )
    }
}

export default GymWorkout;