import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {Alert, Button} from "react-bootstrap";
import WorkoutHelper, {WorkoutTypes} from "../../helpers/workoutHelper";
import GymWorkoutTile from "./GymWorkoutTile";
import _ from "lodash";
import ContentHeader from "../ContentHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay} from "@fortawesome/free-solid-svg-icons";
import {withRouter} from "react-router-dom";

class GymWorkouts extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: '',
            workouts: []
        }

        this.deleteWorkout = this.deleteWorkout.bind(this);
        this.startWorkout = this.startWorkout.bind(this);
    }

    componentDidMount() {
        WorkoutHelper.getWorkouts(WorkoutTypes.gym).then(function (workouts) {
            this.setState({
                workouts
            })

        }.bind(this))
            .catch(error => {
                this.setState({error: 'Treenien haku epäonnistui.'})
            })
    }

    deleteWorkout(id) {
        WorkoutHelper.deleteWorkout(id).then(function (deleted) {
            let workouts = this.state.workouts;

            _.remove(workouts, function(item) {
                return item.id === id;
            });

            this.setState({workouts});

        }.bind(this))
            .catch(error => {
            })
    }

    startWorkout() {
        WorkoutHelper.createWorkout(WorkoutTypes.gym, null).then(function (workout) {
            // Redirect to created workout
            this.props.history.push('/workouts/' + workout.id);
        }.bind(this))
            .catch(error => {
            })
    }

    render() {

        const workouts = this.state.workouts.map((item) => {
            return (
                <GymWorkoutTile
                    key={item.id}
                    id={item.id}
                    title={item.title}
                    date={item.date}
                    rpe={item.rpe}
                    deleteWorkout={this.deleteWorkout}
                    programName={item.program_name}
                />
            )
        })


        return (
            <Row className="mb-5">
                <ContentHeader title="Salitreenit" />

                {this.state.error ? (
                    <Col xs={12}>
                        <Alert variant="danger">
                            {this.state.error}
                        </Alert>
                    </Col>
                ) : (

                    <Col xs={12}>
                        <Row>
                            <Col xs={12} className="mb-3">
                                <Button variant="light"
                                        className="float-right"
                                        onClick={() => this.startWorkout()}>
                                    <FontAwesomeIcon icon={faPlay} /> Aloita treeni
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {workouts}
                            </Col>
                        </Row>
                    </Col>
                )}

            </Row>
        )
    }
}

export default withRouter(GymWorkouts);