import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Alert, Button, Form} from "react-bootstrap";
import _ from "lodash";
import WorkoutSetHelper from "../../helpers/workouts/WorkoutSetHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import ContentHeader from "../ContentHeader";

class SetForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: this.props.initialData,
            error: '',
            newRows: 0,
            repsErrors: [],
            weightErrors: []
        };

        this.addNewSetRow = this.addNewSetRow.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);
        this.removeSetRow = this.removeSetRow.bind(this);
        this.saveSet = this.saveSet.bind(this);
    }

    componentDidMount() {
        if (this.props.setGroup === 0 && this.props.initialData.length === 0) {
            // New group
            this.addNewSetRow();
        } /*else {
            const data = this.props.initialData;
            this.setState({data});
        }*/
    }

    addNewSetRow() {
        let data = this.state.data;
        const newRows = this.state.newRows + 1;

        data.push({
            id: 'new-' + newRows,
            reps: '',
            weight: ''
        });

        this.setState({
            data,
            newRows
        })
    }

    removeSetRow(id) {
        let data = this.state.data;

        _.remove(data, function(item) {
            return item.id === id;
        });

        this.setState({data});
    }

    saveSet() {
        let formValid = true;

        let repsErrors = [];
        let weightErrors = [];

        for (let item of this.state.data) {

            if (!Number.isInteger(parseInt(item.reps))) {
                formValid = false;
                repsErrors[item.id] = "Toistomäärän pitää olla luku."
            }

            if (isNaN(parseFloat(item.weight))) {
                formValid = false;
                weightErrors[item.id] = "Painon pitää olla luku."
            }
        }

        this.setState({repsErrors, weightErrors});

        if (formValid) {

            _.remove(this.state.data, function(item) {
                return !item.reps && !item.weight;
            });

            if (this.props.setGroup === 0) {
                // Create new set group
                WorkoutSetHelper.createSet(
                    this.props.workoutId,
                    this.props.moveId,
                    this.state.data
                ).then(function (newSet) {
                    this.props.showEditedSet(
                        this.props.moveId,
                        newSet.sets,
                        newSet.ref_value
                    );

                }.bind(this))
                    .catch(error => {
                        this.setState({error: 'Tallennus epäonnistui.'})
                    })
            } else {
                WorkoutSetHelper.updateSet(
                    this.props.workoutId,
                    this.props.moveId,
                    this.props.setGroup,
                    this.state.data
                ).then(function (newSet) {
                    this.props.showEditedSet(
                        this.props.moveId,
                        newSet.sets,
                        newSet.ref_value
                    );

                }.bind(this))
                    .catch(error => {
                        this.setState({error: 'Tallennus epäonnistui.'})
                    })
            }
        }
    }

    handleUserInput(e, setRowId) {
        const name = e.target.name;
        let value = e.target.value;

        let data = this.state.data;
        let setRow = _.find(data, {'id': setRowId});
        setRow[name] = value;

        this.setState({
           data
        })
    }

    render() {
        let reps = "";

        if (this.state.data) {
            reps = this.state.data.map((item) => {
                return (
                    <Row id={item.id}>
                        <Col xs={5}>
                            <Form.Group controlId="">
                                <Form.Label>
                                    Paino (kg)
                                </Form.Label>
                                <Form.Control type="text"
                                              name="weight"
                                              value={item.weight}
                                              maxLength="10"
                                              onChange={(e) => this.handleUserInput(e, item.id)}/>

                                {this.state.weightErrors[item.id] && (
                                    <Form.Text className="text-muted">
                                        {this.state.weightErrors[item.id]}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col xs={5}>
                            <Form.Group controlId="">
                                <Form.Label>
                                    Toistot
                                </Form.Label>
                                <Form.Control type="text"
                                              name="reps"
                                              value={item.reps}
                                              maxLength="10"
                                              onChange={(e) => this.handleUserInput(e, item.id)}/>

                                {this.state.repsErrors[item.id] && (
                                   <Form.Text className="text-muted">
                                       {this.state.repsErrors[item.id]}
                                   </Form.Text>
                               )}
                            </Form.Group>
                        </Col>
                        <Col xs={2} className="mt-4 text-right">
                            <Button variant="danger"
                                    className="icon-btn"
                                    onClick={() => this.removeSetRow(item.id)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </Col>
                    </Row>
                )
            });
        }

        return (
            <Row className="mb-5">
                <ContentHeader title="Muokkaa toistoja" />

                <Col xs={12}>
                    {reps}
                </Col>

                <Col xs={12} className="text-right mb-3">
                    <Button variant="light icon-btn"
                            onClick={this.addNewSetRow}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </Col>

                {this.state.error && (
                    <Col xs={12} className="mt-3">
                        <Alert variant="danger">
                            {this.state.error}
                        </Alert>
                    </Col>
                )}

                <Col xs={12}>
                    <Row className="mt-4">

                        <Col xs={6} md={3}>
                            <Button variant="default"
                                    className="btn-block"
                                    onClick={this.props.closeForm}>
                                Peruuta
                            </Button>
                        </Col>
                        <Col xs={6} md={3}>
                            <Button variant="info"
                                    className="btn-block"
                                    onClick={this.saveSet}>
                                Tallenna
                            </Button>
                        </Col>
                    </Row>
                </Col>

            </Row>
        )
    }
}

export default SetForm;