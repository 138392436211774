import React from 'react';
import { withRouter } from 'react-router-dom'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from "react-bootstrap/Card";
import WorkoutProgramMove from "../moves/WorkoutProgramMove";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faPlay, faPlus} from "@fortawesome/free-solid-svg-icons";

import '../../../assets/css/program.css';
import {Button} from "react-bootstrap";
import WorkoutHelper, {WorkoutTypes} from "../../../helpers/workoutHelper";

class WorkoutProgramDay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };

        this.myRef = React.createRef();

        this.toggleOpen = this.toggleOpen.bind(this);
        this.startWorkout = this.startWorkout.bind(this);
    }

    toggleOpen() {
        const isOpen = this.state.isOpen;

        this.setState({isOpen: !isOpen});
    }

    startWorkout() {
        WorkoutHelper.createWorkout(
            WorkoutTypes.gym,
            this.props.id
        ).then(function (workout) {
            // Redirect to created workout
            this.props.history.push('/workouts/' + workout.id);
        }.bind(this))
            .catch(error => {
            })
    }

    render() {
        let latestSetGroup = 0;
        let supersets = 0;

        const moves = this.props.moves.map((move, index) => {
            let supersetGroupNro = 0;

            if (move.set_group > 0) {
                if (move.set_group != latestSetGroup) {
                    supersets++;
                    latestSetGroup = move.set_group;
                }

                supersetGroupNro = supersets;
            }

            return (
                <WorkoutProgramMove
                    key={move.id}
                    programId={this.props.programId}
                    dayId={this.props.dayId}
                    move={move}
                    order={index+1}
                    sets={move.sets}
                    supersetGroupNro={supersetGroupNro}/>
            )
        });

        return (
            <Row>

                <Col xs={12} className="mt-3">
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={12} md={9} onClick={this.toggleOpen} className="clickable float-left" >
                                    {this.props.name}
                                </Col>
                                <Col xs={12} md={3}>
                                    <Button variant="light"
                                            className="icon-btn float-right"
                                            onClick={() => this.startWorkout()}>
                                        <FontAwesomeIcon icon={faPlay} />
                                    </Button>
                                    <Button variant="light"
                                            className="icon-btn float-right mr-2">
                                        <Link to={`/programs/${this.props.programId}/day/${this.props.id}/edit`}>
                                            <FontAwesomeIcon icon={faPencilAlt} />
                                        </Link>
                                    </Button>
                                    <Button variant="light"
                                            className="icon-btn float-right mr-2">
                                        <Link to={{
                                                pathname: "/programs/move/new",
                                                state: {
                                                    dayId: this.props.id,
                                                    programId: this.props.programId
                                                }
                                            }}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Link>
                                    </Button>
                                </Col>
                            </Row>

                        </Card.Header>

                        {this.state.isOpen && (
                            <div className="p-3">
                                {moves}
                            </div>
                        )}
                    </Card>
                </Col>
            </Row>
        )
    }
}

export default withRouter(WorkoutProgramDay);