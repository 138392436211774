import {connect} from "react-redux";
import {axiosInstance as axios, csrfAxiosInstance} from '../axiosConfig';
import AxiosHelpers from '../helpers/axiosHelper';
import store from './../redux/store';
import {loginUser, logoutUser} from '../redux/actions/user';

class UserHelper {

    static login(email, password) {

        return new Promise(function (resolve, reject) {

            axios.post('login', {
                email: email,
                password: password
            })
                .then(res => {
                    store.dispatch(loginUser(email));
                    resolve();

                })
                .catch(error => {
                    const err = AxiosHelpers.handleRequestError(error);
                    reject({status: err.status, code: err.code});
                })
        });
    }

    static getCsrfCookie() {
        return new Promise(function (resolve, reject) {
            try {
                csrfAxiosInstance.get('sanctum/csrf-cookie', {})
                    .then(res => {
                        resolve();
                    })
                    .catch(error => {
                        reject();
                    })
            } catch (e) {
                AxiosHelpers.handleRequestError(e);
                reject();
            }
        });
    }

    static getUser() {
        return new Promise(function (resolve, reject) {
            try {
                axios.get('user', {})
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        reject();
                    })
            } catch (e) {
                AxiosHelpers.handleRequestError(e);
                reject();
            }
        });
    }

    static logout() {

        return new Promise(function (resolve, reject) {

            try {
                axios.post('logout', {
                })
                    .then(res => {
                        store.dispatch(logoutUser());
                        resolve();
                    })
                    .catch(error => {
                        AxiosHelpers.handleRequestError(error);
                        reject();
                    })
            } catch (e) {
                AxiosHelpers.handleRequestError(e);
                reject();
            }


        });
    }
}

export default connect(
    null,
    {loginUser}
)(UserHelper);
