import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Alert} from "react-bootstrap";
import WorkoutProgramDayForm from "./WorkoutProgramDayForm";
import ProgramDayHelper from "../../../helpers/programs/ProgramDayHelper";
import ContentHeader from "../../ContentHeader";

class EditProgramDay extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dayId: this.props.match.params.dayId,
            programId: this.props.match.params.programId,
            error: ''
        };

        this.saveDay = this.saveDay.bind(this);
        this.showError = this.showError.bind(this);
    }

    saveDay(name) {
        ProgramDayHelper.updateDay(
            this.state.programId,
            this.state.dayId,
            name
        ).then(function (result) {
            // Redirect to programs
            this.props.history.push('/programs')

        }.bind(this))
            .catch(error => {
                this.setState({error: 'Päivitys epäonnistui.'})
            })
    }

    showError(text) {
        this.setState({
            error: text
        })
    }

    render() {

        return (
            <Row className="mb-5">
                <ContentHeader title="Muokkaa harjoituspäivää" />

                {this.state.error && (
                    <Col xs={12}>
                        <Alert variant="danger">
                            {this.state.error}
                        </Alert>
                    </Col>
                )}

                <Col xs={12}>
                    <WorkoutProgramDayForm
                        saveBtnText="Tallenna"
                        showError={this.showError}
                        dayId={this.state.dayId}
                        programId={this.state.programId}
                        loadData={true}
                        saveDay={this.saveDay}
                        historyBack={this.props.history.goBack}
                    />
                </Col>

            </Row>
        )
    }
}

export default EditProgramDay;