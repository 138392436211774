import {axiosInstance as axios} from '../../axiosConfig';
import AxiosHelper from './../axiosHelper';


class ProgramDayHelper {

    static getDay(programId, dayId) {
        return new Promise(function (resolve, reject) {
            try {
                axios.get("programs/" + programId + "/days/" + dayId, {
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    static createDay(programId, name) {
        return new Promise(function (resolve, reject) {
            try {
                axios.post("programs/" + programId + "/days", {
                    name
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    static updateDay(programId, dayId, name) {
        return new Promise(function (resolve, reject) {
            try {
                axios.put("programs/" + programId + "/days/" + dayId, {
                    name
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    /*static startWorkout(programId, dayId) {
        return new Promise(function (resolve, reject) {
            try {
                axios.post("programs/" + programId + "/days/" + dayId + "/workout", {
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }*/
}

export default ProgramDayHelper;