import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Alert, Button} from "react-bootstrap";
import ProgramMoveForm from "./ProgramMoveForm";
import ProgramMoveHelper from "../../../helpers/programs/ProgramMoveHelper";
import ContentHeader from "../../ContentHeader";

class NewProgramMove extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dayId: '',
            error: '',
            programId: '',
            showForm: false
        };

        this.createMove = this.createMove.bind(this);
        this.showError = this.showError.bind(this);
    }

    componentDidMount() {
        if (this.props.location.state) {
            if (!Number.isInteger(this.props.location.state.dayId) ||
                !Number.isInteger(this.props.location.state.programId)) {
                this.setState({
                    error: 'Harjoituspäivää ei ole valittuna.',
                    showForm: false
                });
            } else {
                this.setState({
                    dayId: this.props.location.state.dayId,
                    programId: this.props.location.state.programId,
                    showForm: true
                });
            }
        } else {
            this.setState({
                error: 'Harjoituspäivää ei ole valittuna.',
                showForm: false
            });
        }
    }

    createMove(move, rest, comments, sets) {

        ProgramMoveHelper.createMove(
            this.state.programId,
            this.state.dayId,
            move,
            rest,
            comments,
            sets
        ).then(function (result) {
            // Redirect to programs
            this.props.history.push('/programs')

        }.bind(this))
            .catch(error => {
                this.setState({error: 'Harjoitusliikkeen luonti epäonnistui.'})
            })
    }

    showError(text, showForm) {
        this.setState({
            error: text,
            showForm
        })
    }

    render() {

        return (
            <Row className="mb-5">
                <ContentHeader title="Uusi harjoitusliike" />

                {this.state.error && (
                    <Col xs={12} className="mb-3">
                        <Alert variant="danger">
                            {this.state.error}
                        </Alert>
                    </Col>
                )}

                {this.state.showForm ? (
                    <Col xs={12}>
                        <ProgramMoveForm
                            showError={this.showError}
                            loadData={false}
                            saveMove={this.createMove}
                            historyBack={this.props.history.goBack}
                        />
                    </Col>
                ): (
                    <Button variant="default"
                            onClick={this.props.history.goBack}>
                        Peruuta
                    </Button>
                )}
            </Row>
        )
    }
}

export default NewProgramMove;