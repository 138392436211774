import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import _ from 'lodash';
import { add, sub, format, startOfWeek, endOfWeek } from 'date-fns'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import GoalHelper from "../../helpers/goalHelper";
import WorkoutHelper from "../../helpers/workoutHelper";
import DateTimeHelper from "../../helpers/DateTimeHelper";
import WeekCalendar from "./WeekCalendar";

import { getUserEmail } from "../../redux/reducers/user";

import './../../assets/css/dashboard.css';
import {connect} from "react-redux";

class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            goals: [],
            workouts: [],
            startDate: '',
            endDate: ''
        };

        this.getLastWeek = this.getLastWeek.bind(this);
        this.getNextWeek = this.getNextWeek.bind(this);
    }

    componentDidMount() {

        let startDayOfWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
        let endDayOfWeek = endOfWeek(new Date(), { weekStartsOn: 1 });

        startDayOfWeek = format(startDayOfWeek, 'yyyy-MM-dd');
        endDayOfWeek = format(endDayOfWeek, 'yyyy-MM-dd');

        this.setState({
            startDate: startDayOfWeek,
            endDate: endDayOfWeek
        })

        WorkoutHelper.getWorkouts("").then(function (workouts) {
            this.setState({
                workouts
            })

        }.bind(this))
            .catch(error => {
            })

        GoalHelper.getGoals().then(function (goals) {
            this.setState({
                goals
            })

        }.bind(this))
            .catch(error => {
            })
    }

    getLastWeek() {
        const startDate = format(sub(new Date(this.state.startDate), {days: 7}), 'yyyy-MM-dd');
        const endDate = format(sub(new Date(this.state.endDate), {days: 7}), 'yyyy-MM-dd');

        this.setState({
            startDate,
            endDate
        })
    }

    getNextWeek() {
        const startDate = format(add(new Date(this.state.startDate), {days: 7}), 'yyyy-MM-dd');
        const endDate = format(add(new Date(this.state.endDate), {days: 7}), 'yyyy-MM-dd');

        this.setState({
            startDate,
            endDate
        })
    }

    render() {
        const startDate = this.state.startDate;
        const endDate = this.state.endDate;

        let selectedWeekWorkouts = _.filter(this.state.workouts, function(item) {
            let itemDate = new Date(item.date);
            let startDateObj = new Date(startDate + " 00:00:00");
            let endDateObj = new Date(endDate + " 23:59:59");
            return itemDate >= startDateObj && itemDate <= endDateObj;
        });

        const byType = _.countBy(selectedWeekWorkouts, 'group');
        let typesCount = _.map( byType, (value, key) => ({key,value}) );


        // TODO: jos useampi tavoite, voimassaolo
        let goalBars = "";

        if (this.state.goals[0]) {
            goalBars = this.state.goals[0].rules.map((item) => {

                const goalPerWeek = _.find(typesCount, { 'key': item.type});
                let timesDone = 0;

                if (goalPerWeek) {
                    timesDone = goalPerWeek.value;
                }

                let yMax = item.times_per_week;

                if (timesDone > item.times_per_week) {
                    yMax = timesDone;
                }

                const options = {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'bar',
                        height: 40
                    },
                    title: {
                        text: null
                    },
                    xAxis: {
                        visible: false,
                    },
                    yAxis: {
                        visible: false,
                        min: 0,
                        max: yMax
                    },
                    plotOptions: {
                        series: {
                            stacking: 'normal',
                            animation: false,
                            showInLegend: false,
                            borderRadius: 5,
                            //color: 'rgb(161 195 236 / 50%)',
                            color: 'rgb(84 199 162 / 50%)',
                            pointWidth: 20,

                            dataLabels: {
                                enabled: true,
                                color: '#666666',
                                inside: true,
                                align: 'right',
                            }
                        }
                    },
                    tooltip: {
                        enabled: false
                    },
                    series: [{
                        grouping: false,
                        enableMouseTracking: false,
                        showInLegend: false,
                        stacking: false,
                        color: '#dfdcdc78', /*color: 'rgba(0,0,0,0)',*/
                        borderColor: '#d5d3d3',
                        format: '',
                        dataLabels: {
                            enabled: false
                        },
                        data: [yMax]
                    }, {
                        data: [timesDone],
                        dataLabels: {
                            format: timesDone + ' / ' + item.times_per_week,
                            enabled: true,
                            align: 'right',
                            style: {
                                color: 'white',
                                textOutline: false,
                            }
                        }
                    }]
                }
                return (
                    <Row className="goal-charts" key={item.id}>
                        <Col xs={10}>
                            <HighchartsReact
                                key={item.id}
                                highcharts={Highcharts}
                                options={options}
                            />
                        </Col>
                        <Col xs={2}>
                            {GoalHelper.getGoalTypeName(item.type)}
                        </Col>
                    </Row>
                )
            });
        }

        return (
            <Row className="mt-5 mb-5">
                <Col xs={12} className="mt-5 section-header section-header-top">
                    <h5>Treenit</h5>
                </Col>

                <Col xs={1} className="calendar-col-left">
                    <div className="btn calendar-btn calendar-btn-left" onClick={this.getLastWeek}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </div>
                </Col>
                <Col xs={10} className="calendar-col-center">
                    {DateTimeHelper.formatDate(this.state.startDate)} - { DateTimeHelper.formatDate(this.state.endDate) }
                </Col>
                <Col xs={1} className="calendar-col-right">
                    <div className="btn calendar-btn calendar-btn-right" onClick={this.getNextWeek}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                </Col>


                <Col xs={12}>
                    <WeekCalendar
                        workouts={selectedWeekWorkouts}
                        startDate={this.state.startDate} />
                </Col>

                {/*<Col xs={12} className="mt-3">
                    <h5>Treenit</h5>
                </Col>

                <Col xs={12}>
                    {types}
                </Col>*/}

                <Col xs={12} className="mt-5 section-header section-header-top">
                    <h5>Tavoitteet</h5>
                </Col>

                {/*<Col xs={12}>
                    {goals}
                </Col>*/}

                <Col xs={12} className="pt-5 pb-5 section-content">
                    {goalBars}
                </Col>
            </Row>
        );
    }
}

function mapStateToProps(state) {

    return {
        userEmail: getUserEmail(state)
    }
}

export default connect(
    mapStateToProps,
    null
)(Dashboard);