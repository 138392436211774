import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Alert} from "react-bootstrap";
import ProgramHelper from "../../helpers/programs/ProgramHelper";
import WorkoutProgramForm from "./WorkoutProgramForm";
import ContentHeader from "../ContentHeader";

class NewProgram extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: ''
        };

        this.createProgram = this.createProgram.bind(this);
        this.showError = this.showError.bind(this);
    }

    createProgram(name, description) {
        ProgramHelper.createProgram(
            name,
            description
        ).then(function (result) {
            // Redirect to programs
            this.props.history.push('/programs')

        }.bind(this))
            .catch(error => {
                this.setState({error: 'Ohjelman luonti epäonnistui.'})
            })
    }

    showError(text) {
        this.setState({
            error: text
        })
    }

    render() {

        return (
            <Row className="mb-5">
                <ContentHeader title="Uusi ohjelma" />

                {this.state.error && (
                    <Col xs={12} className="mb-3">
                        <Alert variant="danger">
                            {this.state.error}
                        </Alert>
                    </Col>
                )}

                <Col xs={12}>
                    <WorkoutProgramForm
                        saveBtnText="Lisää"
                        showError={this.showError}
                        id=""
                        loadData={false}
                        saveProgram={this.createProgram}
                        historyBack={this.props.history.goBack}
                    />
                </Col>
            </Row>
        )
    }
}

export default NewProgram;