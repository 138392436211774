import axios from 'axios';

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGIN
    }
});

export const csrfAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGIN
    }
});


axiosInstance.defaults.withCredentials = true;
csrfAxiosInstance.defaults.withCredentials = true;