import React from 'react';
import Container from 'react-bootstrap/Container';

import './../assets/css/header.css';
import {Nav, Navbar} from "react-bootstrap";
import UserHelper from "../helpers/userHelper";

import { isUserLogged } from "../redux/reducers/user";
import {connect} from "react-redux";

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.logout = this.logout.bind(this);
    }

    logout() {
        UserHelper.logout();
    }

    render() {
        return (

            <div className="header">
                <Container>

                    <Navbar collapseOnSelect expand="lg">
                        <Navbar.Brand href="/">
                            Minnis.fit
                        </Navbar.Brand>

                        {this.props.logged && (
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        )}

                        {this.props.logged && (
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="ml-auto">
                                    <Nav.Link href="/dashboard">
                                        Dashboard
                                    </Nav.Link>
                                    <Nav.Link href="/programs">
                                        Saliohjelmat
                                    </Nav.Link>
                                    <Nav.Link href="/workouts">
                                        Salitreenit
                                    </Nav.Link>
                                    <Nav.Link  onClick={this.logout}>
                                        Kirjaudu ulos
                                    </Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        )}
                    </Navbar>

                </Container>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        logged: isUserLogged(state)
    }
}

export default connect(mapStateToProps)(Header)