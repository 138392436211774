import React from 'react';
import Container from 'react-bootstrap/Container';

import './../assets/css/footer.css';

class Footer extends React.Component {

    render() {
        return (

            <div className="footer">
                <Container>

                        Minnis.fit

                </Container>
            </div>
        );
    }
}

export default Footer;