import Col from "react-bootstrap/Col";
import React from "react";

const ContentHeader = (props) => {

    return (
        <Col xs={12} className="mt-5 mb-5 page-header section-header-top">
            <h3>{props.title}</h3>
        </Col>
    )
}

export default ContentHeader;