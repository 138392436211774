import React from 'react';
import '../../../assets/css/rpe.css';

function RPEItem(props) {
    // Not selected and none of the values is selected yet
    const notSelected = props.selectedRPE !== props.value && props.selectedRPE !== '';

    // Value selected
    const selected = props.selectedRPE === props.value;

    return (
        <div
            className={`p-1 mb-1 w-100 text-center rpe rpe-${props.value} ${notSelected ? 'rpe-not-selected' : ''} ${selected ? 'rpe-selected' : ''}`}
            onClick={() => props.selectRPE(props.value)}>
            {props.value} {/*RPEHelper.getRPEText(props.value)*/}
        </div>
    );
}

export default RPEItem;