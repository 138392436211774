import {axiosInstance as axios} from '../axiosConfig';
import AxiosHelper from './axiosHelper';


class GoalHelper {

    static getGoals() {
        return new Promise(function (resolve, reject) {
            try {
                axios.get("goals")
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        AxiosHelper.handleRequestError(error);
                        reject([]);
                    })

            } catch (e) {
                AxiosHelper.handleRequestError(e);
                reject([]);
            }
        });
    }

    static getGoalTypeName(type) {
        if (type === 'gym') {
            return "Sali";
        } else if (type === 'yoga') {
            return "Jooga";
        } else if (type === 'cardio') {
            return "Kardio";
        } else if (type === 'basicFitness') {
            return "Peruskunto";
        }
    }
}

export default GoalHelper;