import React from 'react';
import RPEItem from "./RPEiItem";

function RPESelector (props) {
    const arr = Array.from(Array(11).keys());

    return (
        <div className="rpe-selector mt-1">
            {arr.map((value) => {
                return (
                    <RPEItem
                        key={value}
                        value={value}
                        selectedRPE={props.selectedRPE}
                        selectRPE={props.selectRPE}
                    />
                )
            })}
        </div>
    );
}

export default RPESelector;