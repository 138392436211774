import {add, format} from "date-fns";

class DateTimeHelper {

    static addDates(date, addDates) {
        if (date) {
            return DateTimeHelper.formatDate(add(new Date(date), {days: addDates}))
        } else {
            return "";
        }

    }

    static formatDate(date) {
        if (date) {
            return format(new Date(date), 'd.M.yyyy');
        } else {
            return "";
        }
    }

    static formatDateTime(dateTime) {
        if (dateTime) {
            return format(new Date(dateTime), 'd.M.yyyy H:mm');
        } else {
            return "";
        }
    }

    static formatTime(time, isDateTime = false) {
        let newTime = "";
        if (isDateTime) {
            newTime = time;
        } else {
            newTime = "2021-01-01 " + time;
        }

        if (newTime) {
            return format(new Date(newTime), 'H:mm');
        } else {
            return "";
        }
    }

}

export default DateTimeHelper;