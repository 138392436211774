import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DateTimeHelper from "../../helpers/DateTimeHelper";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faDumbbell, faWalking, faSwimmer} from '@fortawesome/free-solid-svg-icons'

import './../../assets/css/workout.css';
import './../../assets/css/weekCalendar.css';
import {Link} from "react-router-dom";

class WorkoutMobile extends React.Component {

    render() {

        const workout = this.props.workout;

        let icon = "";
        let iconClass = "";
        let tileClass = "";

        if (workout.type === "gym") {
            icon = <FontAwesomeIcon icon={faDumbbell} size="2x" />
            iconClass = "w-icon-gym";
            tileClass = "w-tile-gym";
        } else if (workout.type === "walking") {
            icon = <FontAwesomeIcon icon={faWalking} size="2x" />
            iconClass = "w-icon-walking";
            tileClass = "w-tile-walking";
        } else {
            icon = <FontAwesomeIcon icon={faSwimmer} size="2x" />
            iconClass = "w-icon-other";
            tileClass = "w-tile-other";
        }

        return (
            <Link to={`/workouts/${workout.id}`} className="custom-style">
                <Row className={ `mt-3 text-left workout-tile ${tileClass}` } key={workout.id}>
                    <Col xs={3} sm={2} lg={1}>
                        <div className={ `workout-icon ${iconClass}` }>
                            {icon}
                        </div>
                    </Col>
                    <Col xs={9} sm={10} lg={11}>
                        <h6>
                            {workout.title}
                        </h6>
                        <div className="text-small">
                            {DateTimeHelper.formatTime(workout.date, true)}
                        </div>
                    </Col>
                    {/*<Col xs={6} className="text-small">
                    {workout.time && (
                        <div>
                            Kesto: {DateTimeHelper.formatTime(workout.time)} h
                        </div>
                    )}

                    {workout.distance > 0 && (
                        <div>
                            Matka: { workout.distance } km
                        </div>
                    )}

                    <div>
                        Kalorit: {workout.calories} kcal
                    </div>
                </Col>*/}
                </Row>
            </Link>
        )
    }
}

export default WorkoutMobile;